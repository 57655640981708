import { generateRandomNumber } from '@marriott/shared/mi-helper-utils';
import {
  LOCALE_DOMAIN_MAPPING,
  LOCALE_SPECIFIC_MAPPING,
  LOCALE_LANGCODE_MAPPING,
  BREAKPOINT_TABLET,
  BREAKPOINT_DESKTOP,
} from '../constants/CommonConstants';
import { ADOBE_COOKIE, MBOX_COOKIE } from '../constants/OfferCardConstants';

import { ASPECT_RATIO, IMG_TYPE_JPG } from '../constants/OffersHeroConstants';
import { OfferObj } from '../index.types';
import { MILESTOMETERS } from '../organisms/OffersSearchForm/constants/ApplicationConstants';

export const canUseDOM = !!(typeof window !== 'undefined' && window.document);

export const getProcessEnvs = () => {
  if (canUseDOM) {
    const envTag = document.getElementById('__SERVERENV__');
    if (envTag) {
      const envObject = JSON.parse(envTag.innerHTML);
      return envObject;
    }
    return process.env;
  }
  return process.env;
};

export const generateTitle = (
  offerObj: OfferObj,
  titleHelpers: {
    marriottDeals: string;
    inText: string;
  }
): string => {
  if (parseInt(offerObj?.numProperties) > 1) {
    return `${offerObj?.title} | ${titleHelpers.marriottDeals}`;
  } else {
    return `${offerObj?.title ?? ''} ${
      showCompleteTitle(offerObj?.participatingProperties, titleHelpers.inText).inVal
    } ${offerObj?.participatingProperties?.city ?? ''}, ${
      offerObj?.participatingProperties?.stateProvince ?? offerObj?.participatingProperties?.country ?? ''
    } ${showCompleteTitle(offerObj?.participatingProperties, titleHelpers.inText).brandLine} ${
      offerObj?.participatingProperties?.brandName ?? ''
    }`;
  }
};

export const generateI18Title = (
  offerObj: OfferObj,
  template: {
    name: string;
    value: string;
  }
): string => {
  if (parseInt(offerObj?.numProperties) > 1) {
    return template?.value?.replace('$title', offerObj?.title);
  } else {
    let title = template?.value?.replace('$title', offerObj?.title);
    if (offerObj?.participatingProperties?.city) {
      title = title?.replace('$city', offerObj.participatingProperties.city);
    } else {
      title = title?.replace('$city', '');
    }
    if (offerObj?.participatingProperties?.stateProvince) {
      title = title?.replace('$country', offerObj.participatingProperties.stateProvince);
    } else {
      if (offerObj?.participatingProperties?.country) {
        title = title?.replace('$country', offerObj.participatingProperties.country);
      } else {
        title = title?.replace('$country', '');
      }
    }
    if (offerObj?.participatingProperties?.brandName) {
      title = title?.replace('$brandName', offerObj.participatingProperties.brandName);
    } else {
      title = title?.replace('| $brandName', '');
    }
    return title;
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const showCompleteTitle = (locationObj: any, inTextLoalised: string): { inVal: string; brandLine: string } => {
  const completeTitleHelper = {
    inVal: '',
    brandLine: '',
  };
  if (locationObj?.city || locationObj?.stateProvince || locationObj?.country) {
    completeTitleHelper.inVal = inTextLoalised;
  }
  if (locationObj?.brandName) {
    completeTitleHelper.brandLine = '|';
  }
  return completeTitleHelper;
};

export const getFallbackImg = (url: string, imgAspectRatio: string): string => {
  const aspectRatio = imgAspectRatio.toUpperCase();
  switch (aspectRatio) {
    case 'WIDE':
      return `${url}-${ASPECT_RATIO['Wide']}${IMG_TYPE_JPG}`;

    case 'FEATURE':
      return `${url}-${ASPECT_RATIO['Feature']}${IMG_TYPE_JPG}`;

    case 'CLASSIC':
      return `${url}-${ASPECT_RATIO['Classic']}${IMG_TYPE_JPG}`;

    case 'PANO':
      return `${url}-${ASPECT_RATIO['Pano']}${IMG_TYPE_JPG}`;

    default:
      return `${url}-${ASPECT_RATIO['Square']}${IMG_TYPE_JPG}`;
  }
};
export const updateDomain = (languageCodes: string[]): { url: string; hreflang: string }[] => {
  const urlSet: { url: string; hreflang: string }[] = [];
  for (let i = 0; i < languageCodes.length; i++) {
    const langCode = languageCodes[i];
    const key = langCode as keyof typeof LOCALE_DOMAIN_MAPPING;
    const obj = LOCALE_SPECIFIC_MAPPING[key];
    if (obj !== undefined) {
      urlSet.push(obj);
    }
  }
  return urlSet;
};

export const generateOfferUrl = (
  urlTitle: string,
  id: string,
  seoNickname: string,
  numProperties: string,
  marshaCode: string,
  languageCodes: string[],
  acceptLanguage: string,
  isPreview?: boolean
): { url: string; hreflang: string }[] => {
  if (numProperties && parseInt(numProperties) > 1) {
    if (languageCodes?.length) {
      const domainUrls = updateDomain(languageCodes);
      const updatedHreflang = domainUrls.map((item: { url: string; hreflang: string }) => {
        return {
          url: `${item['url']}/offers/${urlTitle}-${id}`,
          hreflang: item['hreflang'],
        };
      });
      return updatedHreflang;
    } else {
      const key = acceptLanguage as keyof typeof LOCALE_LANGCODE_MAPPING;
      const value = LOCALE_LANGCODE_MAPPING[key];
      const domainUrls = updateDomain([value]);
      const updatedHreflang = domainUrls.map((item: { url: string; hreflang: string }) => {
        const url = isPreview ? `${item['url']}/offers/preview/${id}` : `${item['url']}/offers/${urlTitle}-${id}`;
        return {
          url: url,
          hreflang: item['hreflang'],
        };
      });
      return updatedHreflang;
    }
  } else {
    return generateOfferDetailUrl(
      urlTitle,
      id,
      seoNickname,
      marshaCode,
      languageCodes,
      acceptLanguage,
      isPreview,
      false
    );
  }
};

export const generateOfferDetailUrl = (
  urlTitle: string,
  id: string,
  seoNickname: string,
  marshaCode: string,
  languageCodes: string[],
  acceptLanguage: string,
  isPreview?: boolean,
  isCTAUrl?: boolean
): { url: string; hreflang: string }[] => {
  if (languageCodes?.length) {
    console.log('isPreview ', isPreview);
    const domainUrls = updateDomain(languageCodes);
    const updatedHreflang = domainUrls.map((item: { url: string; hreflang: string }) => {
      return {
        url: `${item['url']}/offers/${urlTitle}-${id}/${marshaCode}-${seoNickname}`,
        hreflang: item['hreflang'],
      };
    });
    return updatedHreflang;
  } else {
    const key = acceptLanguage as keyof typeof LOCALE_LANGCODE_MAPPING;
    const value = LOCALE_LANGCODE_MAPPING[key];
    const domainUrls = updateDomain([value]);
    const updatedHreflang = domainUrls.map((item: { url: string; hreflang: string }) => {
      const url = `${isCTAUrl ? '' : item['url']}/offers/${urlTitle}-${id}/${marshaCode}-${seoNickname}`;
      return {
        url: url,
        hreflang: item['hreflang'],
      };
    });
    return updatedHreflang;
  }
};

export function convertMetersToMiles(num: number | undefined | null) {
  if (num) {
    return num / MILESTOMETERS;
  }
  return num;
}
export const extractValue = (str: string, key: string, strDelimeter: string) => {
  const startIndex = str.indexOf(key);

  if (startIndex === -1) {
    return null;
  }
  const firstHashIndex = startIndex + key.length;
  const secondHashIndex = str.indexOf(strDelimeter, firstHashIndex);
  if (secondHashIndex === -1) {
    return null;
  }
  return str.substring(firstHashIndex, secondHashIndex);
};
export const getCookieValue = (cookieString: string, cookieName: string): string => {
  const mbox =
    cookieString.split(';').length > 1
      ? cookieString.split(';').filter(cookie => {
          const [key, value] = cookie.trim().split('=');
          if (key && value) {
            return key === cookieName;
          }
          return false;
        })
      : [];

  const str1 =
    mbox.length && mbox.length > 0
      ? mbox[0].split('=').length && mbox[0].split('=').length > 1
        ? mbox[0].split('=')[1]
        : ''
      : '';
  return str1;
};
export const getMbopCookie = (cookies: string, cookieName: string): string => {
  const cookieString = decodeURIComponent(cookies);
  let cookievalue = '';
  const strValue = getCookieValue(cookieString, cookieName);
  if (cookieName === MBOX_COOKIE) {
    cookievalue = extractValue(strValue, 'session#', '#') ?? '';
  } else if (cookieName === ADOBE_COOKIE) {
    cookievalue = extractValue(strValue, 'MCMID%', '%') ?? '';
  }
  return cookievalue;
};
export const useGetBreakpoint = (): string | undefined => {
  if (typeof window !== 'undefined' && window?.screen && window?.screen?.width) {
    return window.innerWidth > BREAKPOINT_DESKTOP
      ? 'desktop'
      : window.innerWidth > BREAKPOINT_TABLET
      ? 'tablet'
      : 'mobile';
  } else {
    return undefined;
  }
};

export const generateSeoFriendlyText = (text: string) => {
  return text ? text.toLocaleLowerCase().replace(/[` ~!@#$%^&*()_|+\-=?;:'",.<>{}[\]\\/]/gi, '-') : '';
};

export const generateRandomAlphaNumericString = () => {
  return 'generated-'.concat(
    Math.floor(generateRandomNumber() * 1e32)
      .toString(36)
      .toLowerCase()
  );
};

export const getNthOccurence = (str: string, subStr: string, index: number) => {
  return str?.split(subStr, index)?.join(subStr)?.length;
};
