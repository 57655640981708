const LOCALE_ZH_CN = 'zh-CN';

/**
 * isCNLocale Used to determine if it is CN page
 * @param currentLocale
 * @returns boolean
 */
export const isCNLocale = (currentLocale: string | undefined): boolean => {
  const locale = currentLocale?.replace('_', '-');
  return locale?.toLowerCase() === LOCALE_ZH_CN.toLowerCase();
};
