import dynamic from 'next/dynamic';
import { Container, MapTo } from '@adobe/aem-react-editable-components';

/* eslint-disable @nx/enforce-module-boundaries */
import '../../libs/aemComponents/Container'; // TODO: Why are these imports not aliased?
import '../../libs/aemComponents/ExperienceFragment';
import {
  EditableHTLWrapper,
  StaticWrapper,
  CardHorizontalMini,
  CardLayered,
  CardVertical,
  CardCarouselContainer,
  CardHorizontalFeature,
  BannerLayered,
  BrandRibbon,
  IconBlock,
  AccordionContainer,
  CobrandCardHorizontalFlexible,
  Hero,
  Footer,
  // LinkList,
  NavigationContainerWrapper,
  NavigationUtilityLinkWrapper,
  NavigationWrapper,
  GeneralMessage,
} from '@marriott/mi-common-static-components';
/* eslint-enable @nx/enforce-module-boundaries */
import { AEMText, SearchFormEditable } from '@marriott/mi-shop-components';
import { OfferCardCarousel } from '@marriott/mi-offers-components';
import {
  CarouselContainerSkeletonLoader,
  BannerLayeredSkeletonLoader,
  CardHorizontalMiniSkeletonLoader,
  HeroBanner,
  IconFeatureBanner,
} from '@marriott/mi-homepage-components';
import { MerchandisingSmallBanner, MerchandisingHeroBanner } from '@marriott/mi-merch-components';

const CardCarouselContainerDynamic = dynamic(
  () => import('@marriott/mi-common-static-components').then(module => module.CardCarouselContainer),
  {
    ssr: true,
    loading: () => <CarouselContainerSkeletonLoader />,
  }
);

const CardHorizontalMiniDynamic = dynamic(
  () => import('@marriott/mi-common-static-components').then(module => module.CardHorizontalMini),
  {
    ssr: true,
    loading: () => <CardHorizontalMiniSkeletonLoader />,
  }
);

const BannerLayeredDynamic = dynamic(
  () => import('@marriott/mi-common-static-components').then(module => module.BannerLayered),
  {
    ssr: true,
    loading: () => <BannerLayeredSkeletonLoader />,
  }
);

const FooterDynamic = dynamic(() => import('@marriott/mi-common-static-components').then(module => module.Footer), {
  ssr: false,
});

// TODO: AEM component paths are having issues when picked using ENV vars
// Needs to be investigated as tech debt
export const AEM_REACT_MAPPING = {
  ['mi-aem-common-spa/components/content/generalmessage']: GeneralMessage,
  [`mi-aem-homepage-spa/components/text`]: AEMText,
  [`mi-aem-common-spa/components/common/staticwrapper`]: StaticWrapper,
  [`mi-aem-homepage-spa/components/content/herobanner`]: HeroBanner,
  [`mi-aem-common-spa/components/content/cardhorizontalmini`]: CardHorizontalMini,
  [`mi-aem-common-spa/components/content/cardlayered`]: CardLayered,
  [`mi-aem-common-spa/components/content/cardcarouselcontainer`]: CardCarouselContainer,
  [`mi-aem-common-spa/components/content/cardvertical`]: CardVertical,
  [`mi-aem-common-spa/components/content/cardhorizontalfeature`]: CardHorizontalFeature,
  [`mi-aem-common-spa/components/content/bannerlayered`]: BannerLayered,
  [`mi-aem-common-spa/components/content/brandribbon`]: BrandRibbon,
  [`mi-aem-shop-spa/components/content/homepagesearch/v1/homepagesearch`]: SearchFormEditable,
  [`mi-aem-shop-spa/components/content/baselinesearchform/v1/baselinesearchform`]: SearchFormEditable,
  [`mi-aem-common-spa/components/content/iconblock`]: IconBlock,
  [`mi-aem-common-spa/components/content/accordioncontainer`]: AccordionContainer,
  [`mi-aem-common-spa/components/content/cobrandcardhorizontalflexible`]: CobrandCardHorizontalFlexible,
  [`mi-aem-offers-spa/components/offers-cards`]: OfferCardCarousel,
  [`mi-aem-common-spa/components/experiencefragment`]: Container,
  [`mi-aem-common-spa/components/content/hero`]: Hero,
  [`mi-aem-homepage-spa/components/content/iconfeaturebanner`]: IconFeatureBanner,
  [`mi-aem-homepage-spa/components/content/universal-footer`]: Footer,
  // [`mi-aem-common-spa/components/content/linklist`]: LinkList,
  [`mi-aem-homepage-spa/components/content/global-navigation`]: NavigationContainerWrapper,
  [`mi-aem-homepage-spa/components/content/utilityNavigation`]: NavigationUtilityLinkWrapper,
  [`mi-aem-homepage-spa/components/content/primaryNavigation`]: NavigationWrapper,
  [`mi-aem-common-spa/components/content/merchandisingsmallbanner`]: MerchandisingSmallBanner,
  [`mi-aem-common-spa/components/content/merchandisingherobanner`]: MerchandisingHeroBanner,
};

export const defineComponentMapping = (allowedComponents, pagePath, isAuthorMode, pageProps) => {
  MapTo('mi-aem-common-spa/components/content/generalmessage')(GeneralMessage);
  MapTo(`mi-aem-homepage-spa/components/text`)(AEMText);
  MapTo(`mi-aem-common-spa/components/common/staticwrapper`)(StaticWrapper);
  MapTo(`mi-aem-homepage-spa/components/content/herobanner`)(HeroBanner);
  MapTo(`mi-aem-homepage-spa/components/content/herobanner`)(props => (
    <HeroBanner {...props} isAuthorMode={isAuthorMode} />
  ));
  MapTo(`mi-aem-common-spa/components/content/cardlayered`)(CardLayered);
  MapTo(`mi-aem-common-spa/components/content/cardvertical`)(CardVertical);
  MapTo(`mi-aem-common-spa/components/content/cardhorizontalmini`)(
    isAuthorMode ? CardHorizontalMini : CardHorizontalMiniDynamic
  );
  MapTo(`mi-aem-common-spa/components/content/cardcarouselcontainer`)(props =>
    isAuthorMode ? (
      <CardCarouselContainer {...props} isAuthorMode={isAuthorMode} allowedComponents={allowedComponents} />
    ) : (
      <CardCarouselContainerDynamic {...props} isAuthorMode={isAuthorMode} allowedComponents={allowedComponents} />
    )
  );
  MapTo(`mi-aem-common-spa/components/content/cardhorizontalfeature`)(CardHorizontalFeature);
  MapTo(`mi-aem-common-spa/components/content/bannerlayered`)(isAuthorMode ? BannerLayered : BannerLayeredDynamic);
  MapTo(`mi-aem-common-spa/components/content/brandribbon`)(BrandRibbon);
  MapTo(`mi-aem-shop-spa/components/content/homepagesearch/v1/homepagesearch`)(props => (
    <SearchFormEditable {...props} currentLocale={pageProps.currentLocale} />
  ));
  MapTo(`mi-aem-shop-spa/components/content/baselinesearchform/v1/baselinesearchform`)(props => (
    <SearchFormEditable {...props} currentLocale={pageProps.currentLocale} />
  ));
  MapTo(`mi-aem-common-spa/components/content/iconblock`)(IconBlock);
  MapTo(`mi-aem-common-spa/components/content/accordioncontainer`)(props => (
    <AccordionContainer {...props} isAuthorMode={isAuthorMode} allowedComponents={allowedComponents} />
  ));
  MapTo(`mi-aem-common-spa/components/content/cobrandcardhorizontalflexible`)(CobrandCardHorizontalFlexible);
  MapTo(`mi-aem-offers-spa/components/offers-cards`)(
    props => (
      // Rename to address broken homepage build caused by DXP-7950
      <OfferCardCarousel
        {...props}
        offersData={pageProps.productSpecificResponse}
        isAuthorMode={isAuthorMode}
        acceptLanguage={pageProps?.currentLocale}
        requestId={pageProps?.requestId}
        cookie={pageProps?.headersData['cookie']}
      />
    ),
    {
      emptyLabel: 'Offers Card Carousel',
      isEmpty: false,
    }
  );
  MapTo(`mi-aem-common-spa/components/content/hero`)(Hero);
  MapTo(`mi-aem-homepage-spa/components/content/iconfeaturebanner`)(IconFeatureBanner);

  MapTo(`mi-aem-homepage-spa/components/content/universal-footer`)(props =>
    isAuthorMode ? (
      <Footer {...props} isAuthorMode={isAuthorMode} />
    ) : (
      <FooterDynamic {...props} isAuthorMode={isAuthorMode} />
    )
  );
  // MapTo(`mi-aem-common-spa/components/content/linklist`)(LinkList);
  MapTo(`mi-aem-homepage-spa/components/content/global-navigation`)(props => (
    <NavigationContainerWrapper {...props} isAuthorMode={isAuthorMode} />
  ));
  MapTo(`mi-aem-homepage-spa/components/content/utilityNavigation`)(NavigationUtilityLinkWrapper);
  MapTo(`mi-aem-homepage-spa/components/content/primaryNavigation`)(NavigationWrapper);

  MapTo(`mi-aem-common-spa/components/content/merchandisingsmallbanner`)(
    props => <MerchandisingSmallBanner {...props} isAuthorMode={isAuthorMode} />,
    {
      emptyLabel: 'MerchandesingSmallBanner',
      isEmpty: false,
    }
  );

  MapTo(`mi-aem-common-spa/components/content/merchandisingherobanner`)(
    props => <MerchandisingHeroBanner {...props} isAuthorMode={isAuthorMode} />,
    {
      emptyLabel: 'MerchandisingHeroBanner',
      isEmpty: false,
    }
  );

  const existingMappings = Object.keys(AEM_REACT_MAPPING);
  if (allowedComponents) {
    allowedComponents.forEach(mapping => {
      const baseModelPath = '/jcr:content/root/responsivegrid/';
      const componentPath = mapping.path.replace(pagePath, '').replace(baseModelPath, '');

      if (existingMappings.indexOf(componentPath) === -1) {
        MapTo(componentPath)(EditableHTLWrapper, {
          emptyLabel: mapping.title,
          isEmpty: false,
        });
      } else {
        console.log('mapping exists', componentPath);
      }
    });
  }
};
