/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useMemo, useRef, useState, useId } from 'react';
import clsx from 'clsx';
import { CardCarouselContainer, CardVerticalVariations } from '@marriott/mi-ui-library';
import { useQuery, useLazyQuery } from '@apollo/client';

import config from './config';
import { OfferCardsProps } from './OfferCardCarousel.types';
import { getMbopCookie } from '../../utils/CommonUtils';
import {
  ASPECT_RATIO_SQUARE,
  MBOX_COOKIE,
  CARD_LAYERED_VARIATION,
  IMAGE_ALT_TEXT,
  IMPRESSION_TRACK,
  MERCHANDISING_CATEGORY,
  NO_OF_CARDS_TABLET,
  OFFER_RECIPE_NONE,
  OFFER_CAROUSEL_URL,
  OFFER_CAROUSEL_URL_REFERRER,
  OFFER_CAROUSEL_CHANNEL,
  OFFER_CAROUSEL_ERROR_POLICY,
  MEMBER_EXCLUSIVE,
  CARDSTYPE_VERTICAL,
  ASPECT_RATIO_WIDE,
  LAYERED_WIDE,
  CARD_LAYERED_WIDE,
  VARIATION_INVERSE,
  OFFER_RECIPE_PROPERTY_NON_STAY,
  RESORT_OFFERS_RECIPE,
  OFFER_RECIPE_ELITE_EXCLUSIVE,
  CARD_SQUARE,
  DESTINATION_OFFERS_RECIPE,
} from '../../constants/OfferCardConstants';
import {
  phoenixOffersCarousel,
  phoenixOffersFallbackOffers,
  phoenixOfferPreview,
  PhoenixDestinationOffersSearchByArea,
  PhoenixDestinationOffersSearchByGeolocation,
} from '@marriott/mi-offers-graphql';
import {
  addSubDirectoryPrefix,
  makeDomainSpecificContent,
  checkEliteUser,
  getEdgeHost,
  generateUXLVariablesForDestination,
} from '../../utils/OfferUtils';
import { useGetBreakpoint } from '../../utils/CommonUtils';
import { StyledOfferCarouselContainer } from './OfferCardCaousel.styles';
import { useClientEnvVarsStore } from '@marriott/mi-store-utils';
import { getWCMModeFlagValue, isNonEmptyString } from '@marriott/shared/mi-helper-utils';
import { DEFAULT_ACCEPT_LANGUAGE } from '@marriott/mi-headless-utils';
import { OffersCardCarouselSkeletonLoader } from './OffersCardCarouselSkeletonLoader';

export const CardCarousel: React.FC<OfferCardsProps> = props => {
  const { model, acceptLanguage, isAuthorMode, requestId, DestinationDetails } = props;
  const {
    latitude = '',
    longitude = '',
    stateCode = '',
    countryCode = '',
    isStateEnabled = false,
    isCountryEnabled = false,
    destinationName = '',
    isPOI = false,
    city = '',
  } = DestinationDetails ?? {};
  const {
    openInaNewTab: openInaNewTabStr = 'false',
    noOfCards,
    ctaLink = '',
    cardsType = CARD_LAYERED_VARIATION,
    variation,
  } = model;
  const isPreview = props?.offersData?.isPreview === 'true' ? true : false;
  const defaultOffersReceipe = model?.offersRecipe;
  const isOfferRecipeNone = defaultOffersReceipe === OFFER_RECIPE_NONE;
  const isRecipePropNonStayOffers = defaultOffersReceipe === OFFER_RECIPE_PROPERTY_NON_STAY;
  const listjson = model?.fallbackOfferIdsList;
  const offerslistjson = listjson?.map((object: { offersId: string }) => object.offersId);
  const [offerResult, setResultOffer] = useState<any>([]);
  const [cardCount, setCardCount] = useState(0);
  const [keys, setKeys] = useState<any>([]);
  const [moreCTALink, setMoreCTALink] = useState(ctaLink);
  const [isMobileViewPort, setIsMobileViewPort] = useState(useGetBreakpoint() === 'mobile');
  const acceptLang = isNonEmptyString(acceptLanguage) ? acceptLanguage : DEFAULT_ACCEPT_LANGUAGE;
  const openInaNewTab = JSON.parse(openInaNewTabStr);
  const isServer = !(typeof window != 'undefined' && window.document);
  const { envVarsObject } = useClientEnvVarsStore();
  const propsMarshCode = typeof window !== 'undefined' ? [window?.dataLayer?.['prop_marsha_code']] : [];
  const timestamp = useId(); // This id won't change with new component renders.
  const dataLoaded = useRef<boolean>(false);
  const { isEliteUser, memberLevel } =
    typeof window !== 'undefined' ? checkEliteUser() : { isEliteUser: false, memberLevel: '' };
  const isRecipeEliteExclusive = isEliteUser ? defaultOffersReceipe === OFFER_RECIPE_ELITE_EXCLUSIVE : false;

  let searchCriteria = `generated-${timestamp}`;
  let cookieSearchCriteria = null;
  let sessionId = null;
  let cookie = null;

  cookie = isServer ? props?.cookie : document?.cookie;
  sessionId = cookie ? getMbopCookie(cookie, MBOX_COOKIE) : null;

  const checkIsWCMModeResortOffers = () => {
    return getWCMModeFlagValue() && defaultOffersReceipe === RESORT_OFFERS_RECIPE;
  };
  // WEB-70169 - using this to excude the mock file from production build
  const GetCarouselUXLMockJson = async (isAuthorMode: boolean) => {
    if (process.env['NODE_ENV'] === 'development' || isAuthorMode) {
      if (noOfCards === 2 && cardsType === LAYERED_WIDE) {
        const CarouselUXLMockJson = await import('./__mock__/OfferCarouselWideCardUXL.mock.json'); //Load mock model dynamically only for dev mode
        return CarouselUXLMockJson;
      } else if (noOfCards === 3 && cardsType === CARD_SQUARE) {
        const CarouselUXLMockJson = await import('./__mock__/OfferCarouselSquareCardUXL.mock.json'); //Load mock model dynamically only for dev mode
        return CarouselUXLMockJson;
      } else if (noOfCards === 4 && cardsType === CARD_SQUARE) {
        const CarouselUXLMockJson = await import('./__mock__/OfferCarouselUXL.mock.json'); //Load mock model dynamically only for dev mode
        return CarouselUXLMockJson;
      } else if (variation === 'combo') {
        const CarouselUXLMockJson = await import('./__mock__/OfferCarouselComboCardUXL.mock.json'); //Load mock model dynamically only for dev mode
        return CarouselUXLMockJson;
      }
    }
    return {};
  };
  const isWcmModeDisabledFlag = checkIsWCMModeResortOffers();

  //to identify locale and activities are returned respective of that locale
  const workspaceId =
    typeof window !== 'undefined'
      ? window?._satellite?.['_container']?.dataElements?.at_property?.settings?.source?.call()
      : null;
  //to retrieve the latest user profile attributes cached on the edge servers
  const edgeHost = cookie ? getEdgeHost(cookie) : null;

  const resortRequestVariables =
    defaultOffersReceipe === RESORT_OFFERS_RECIPE
      ? {
          region: model?.regionControl,
          resortTypes: model?.activities,
        }
      : {};

  const destinationRequestVariables =
    defaultOffersReceipe === DESTINATION_OFFERS_RECIPE
      ? generateUXLVariablesForDestination(
          latitude,
          longitude,
          stateCode,
          countryCode,
          isStateEnabled,
          isCountryEnabled,
          isPOI
        )
      : {};

  const targetingCriteria = {
    targetingCriteria: {
      workspaceId: workspaceId, // Adobe Target Locale specific workspace Id
      ...(isRecipePropNonStayOffers && { propertyIds: propsMarshCode }),
      ...resortRequestVariables,
      ...(isRecipeEliteExclusive && isEliteUser && { memberLevel }),
      ...(edgeHost && { edgeHost }),
    },
  };

  const offersSearchInputVar = {
    offersSearchInput: {
      queries: [
        {
          id: 'offers',
          values: offerslistjson && offerslistjson.length > 0 ? JSON.parse(JSON.stringify(offerslistjson)) : '[]',
        },
      ],
    },
  };

  // Memoize all variables that affect the query,
  // to prevent re-triggering useQuery if component re-renders.
  const skipQuery =
    useMemo(() => {
      return (!isAuthorMode && dataLoaded.current) || (isAuthorMode && isWcmModeDisabledFlag && dataLoaded.current);
    }, [isAuthorMode, dataLoaded.current]) ||
    (isAuthorMode && !isWcmModeDisabledFlag) ||
    dataLoaded.current ||
    isOfferRecipeNone ||
    !isNonEmptyString(acceptLang);
  // Do not execute query unless Mbox-Session-ID is available.
  // Would require a workaround for local dev where there won't be a cookie.
  // || !sessionId;

  cookieSearchCriteria =
    typeof window !== 'undefined'
      ? window?._satellite?.['getVisitorId']()?._fields?.MCMID?.length === 38
        ? window._satellite?.['getVisitorId']()._fields.MCMID
        : searchCriteria
      : searchCriteria;

  if (cookieSearchCriteria) {
    searchCriteria = cookieSearchCriteria;
  }

  const requestVariables = {
    optimizedOffersInput: {
      action: defaultOffersReceipe,
      source: {
        channel: OFFER_CAROUSEL_CHANNEL,
        url: OFFER_CAROUSEL_URL,
        urlReferrer: OFFER_CAROUSEL_URL_REFERRER,
      },
      searchCriteria: {
        id: searchCriteria,
        ...(isEliteUser && isRecipeEliteExclusive && { isIncludeGatedOffer: true }),
      },
      ...targetingCriteria,
    },
    ...offersSearchInputVar,
  };

  const fallbackRequestVariables = {
    ...offersSearchInputVar,
  };

  const getDestinationOffersQuery = () => {
    if (isPOI || (!isStateEnabled && !isCountryEnabled)) {
      return PhoenixDestinationOffersSearchByGeolocation;
    } else {
      return PhoenixDestinationOffersSearchByArea;
    }
  };
  const queryGQ = isPreview
    ? phoenixOfferPreview
    : defaultOffersReceipe === DESTINATION_OFFERS_RECIPE
    ? getDestinationOffersQuery()
    : phoenixOffersCarousel;

  //Recipe useQuery
  const { loading: isDataLoading } = useQuery(queryGQ, {
    fetchPolicy: isServer ? 'network-only' : 'cache-first',
    errorPolicy: OFFER_CAROUSEL_ERROR_POLICY,
    variables: isPreview
      ? { offerPreviewId: props?.offersData?.offerId }
      : defaultOffersReceipe === DESTINATION_OFFERS_RECIPE
      ? destinationRequestVariables
      : requestVariables,
    onCompleted: async queryData => {
      dataLoaded.current = true;
      if ((queryData && !isAuthorMode) || (queryData && isAuthorMode && isWcmModeDisabledFlag)) {
        if (checkNoDataAvailable(queryData)) {
          loadFallbackQuery();
        } else {
          const mappedUxlData =
            defaultOffersReceipe === DESTINATION_OFFERS_RECIPE
              ? processDestinationOffersUxlData(queryData, false)
              : processUxlData(isPreview ? reformPreviewResponse(queryData) : queryData, false);
          processOfferCarousel(mappedUxlData);
        }
      } else if (isAuthorMode && !isWcmModeDisabledFlag) {
        const CarouselMockUXL = await GetCarouselUXLMockJson(isAuthorMode);
        const mappedUxlData = processUxlData(CarouselMockUXL, false);
        processOfferCarousel(mappedUxlData);
      } else if (!searchCriteria) {
        loadFallbackQuery();
      }
    },

    context: {
      headers: {
        // Only attach headers if they are not null or empty strings,
        // since anything passed on the query will override defaults.
        ...(requestId?.length > 0 && { 'x-request-id': requestId }),
        ...(sessionId && sessionId?.length > 0 && { 'Mbox-Session-ID': sessionId }),
        ...(acceptLang?.length > 0 && { 'accept-language': acceptLang }),
        ...(envVarsObject?.['NEXT_PUBLIC_UAT_AUTH_TOKEN'] &&
          isWcmModeDisabledFlag && { Authorization: envVarsObject?.['NEXT_PUBLIC_UAT_AUTH_TOKEN'] }),
      },
    },

    skip: skipQuery,
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const fallbackComplete = (data: any) => {
    if (data) {
      if (data?.fallbackOffers?.offerCollection) {
        const mappedUxlData = processUxlData(data, true);
        processOfferCarousel(mappedUxlData);
      }
    }
  };

  //fallback query
  const [
    loadFallbackQuery,
    { data: _fallbackResults, error: _fallbackError, called: fallbackCalled, loading: isLazyLoading },
  ] = useLazyQuery(phoenixOffersFallbackOffers, {
    fetchPolicy: isServer ? 'network-only' : 'cache-first',
    errorPolicy: OFFER_CAROUSEL_ERROR_POLICY,
    variables: fallbackRequestVariables,
    context: {
      headers: {
        // Only attach headers if they are not null or empty strings,
        // since anything passed on the query will override mi-apollo-client-utils defaults.
        ...(requestId?.length > 0 && { 'x-request-id': requestId }),
        ...(acceptLang?.length > 0 && { 'accept-language': acceptLang }),
      },
    },
    onCompleted: fallbackComplete,
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const checkNoDataAvailable = (queryData: any) => {
    if (isPreview) {
      if (!queryData) return true;
      else return false;
    } else {
      //fallback query called when main recipe returns cards less than authored packs instead of hard coded 3 value
      if (defaultOffersReceipe !== DESTINATION_OFFERS_RECIPE) {
        if (!queryData?.offersCarousel?.length || queryData?.offersCarousel?.[0]?.offerCollection.length < noOfCards) {
          return true;
        } else return false;
      } else if (isPOI || (!isStateEnabled && !isCountryEnabled)) {
        if (queryData?.offersSearchByGeolocation?.edges.length < noOfCards) {
          return true;
        } else return false;
      } else if ((!isStateEnabled && isCountryEnabled) || (isStateEnabled && !isCountryEnabled)) {
        if (queryData?.offersSearchByArea?.edges.length < noOfCards) {
          return true;
        } else return false;
      } else {
        return false;
      }
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const reformPreviewResponse = (response: any) => {
    return {
      offersCarousel: [
        {
          __typename: response?.offerPreview['__typename'],
          experienceId: response?.offerPreview['id'],
          offerCollection: [
            {
              catalogId: response?.offerPreview['id'],
              offer: response?.offerPreview?.offer,
            },
          ],
        },
      ],
    };
  };

  useEffect(() => {
    function handleResize() {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      setIsMobileViewPort(useGetBreakpoint() === 'mobile');
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // CNWEB-2078 - Invoke the fallback query if the offer recipe is set to `none`
  useEffect(() => {
    if (isOfferRecipeNone && !fallbackCalled) {
      loadFallbackQuery();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const GetOfferCardsInAuthorMode = async () => {
    const CarouselUXLMock = await GetCarouselUXLMockJson(isAuthorMode);
    const mappedUxlData = processUxlData(CarouselUXLMock, false);
    processOfferCarousel(mappedUxlData);
  };

  //WEB-74989 Load offer cards in author mode
  useEffect(() => {
    if (isAuthorMode && !isWcmModeDisabledFlag) {
      GetOfferCardsInAuthorMode();
    }
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const processDestinationOffersUxlData = (uxlData: any, isFallback: boolean) => {
    let response;

    if (isFallback) {
      response = uxlData?.fallbackOffers?.offerCollection || [];
    } else {
      if (isPOI || (!isStateEnabled && !isCountryEnabled)) {
        response = uxlData?.offersSearchByGeolocation?.edges || [];
      } else {
        response = uxlData?.offersSearchByArea?.edges || [];
      }
    }
    const maxOffersCount = model?.maxOffersCount ?? response?.length;
    const ASPECT_RATIO_UXL_FIELD_MAPPING = {
      Wide: 'wideHorizontal',
      Square: 'square',
    };
    const imageRatio = cardsType === LAYERED_WIDE ? ASPECT_RATIO_WIDE : ASPECT_RATIO_SQUARE;
    const defaultOffers = response
      ?.filter((offer: any) => offer?.node?.title)
      .slice(0, maxOffersCount)
      .map((item: any) => {
        const objKey = imageRatio as keyof typeof ASPECT_RATIO_UXL_FIELD_MAPPING;
        const DACImagePath = item?.node?.media?.primaryImage?.imageUrls?.[ASPECT_RATIO_UXL_FIELD_MAPPING?.[objKey]];
        let imageUrl = '';

        if (DACImagePath) {
          imageUrl = DACImagePath;
        } else {
          const keyData = item?.node?.photos?.images;
          keyData?.map((key: any) => {
            if (key.aspectRatio === imageRatio) {
              imageUrl = key.url;
            }
          });
        }
        return {
          image: imageUrl,
          header: item.node.title,
          propertyName: item.node.participatingProperties?.properties?.[0]?.basicInformation?.name,
          ctaUrl: item.node.url,
          propertiesCount: item.node.numProperties,
          memberLevel: item.node.memberLevel,
          description: item.node.descriptionTeaser,
        };
      });

    const updatedMoreCTALink = moreCTALink?.replace('{destinationName}', destinationName);
    setMoreCTALink(updatedMoreCTALink);
    return { offersData: defaultOffers, isFallback };
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const processUxlData = (uxlData: any, isFallback: boolean) => {
    let response;
    let experienceId;
    let concatenatedOfferIds = '';
    if (isFallback) {
      response = uxlData?.fallbackOffers?.offerCollection || [];
    } else {
      response = (uxlData?.offersCarousel?.length && uxlData?.offersCarousel[0]?.offerCollection) || [];
      experienceId = uxlData?.offersCarousel?.length ? uxlData?.offersCarousel[0]?.experienceId : '';
    }

    const maxOffersCount = model?.maxOffersCount ?? response?.length;
    const ASPECT_RATIO_UXL_FIELD_MAPPING = {
      Wide: 'wideHorizontal',
      Square: 'square',
    };
    const imageRatio = cardsType === LAYERED_WIDE ? ASPECT_RATIO_WIDE : ASPECT_RATIO_SQUARE;

    const defaultOffers = response
      ?.filter((offer: any) => offer?.offer?.title)
      .slice(0, maxOffersCount)
      .map((item: any, index: number) => {
        const objKey = imageRatio as keyof typeof ASPECT_RATIO_UXL_FIELD_MAPPING;
        const DACImagePath = item?.offer?.media?.primaryImage?.imageUrls?.[ASPECT_RATIO_UXL_FIELD_MAPPING?.[objKey]];
        let imageUrl = '';

        if (DACImagePath) {
          imageUrl = DACImagePath;
        } else {
          const keyData = item?.offer?.photos?.images;
          keyData?.map((key: any) => {
            if (key.aspectRatio === imageRatio) {
              imageUrl = key.url;
            }
          });
        }

        if (index !== 0) {
          concatenatedOfferIds += ',';
        }
        concatenatedOfferIds += item?.offer?.id;
        return {
          image: imageUrl,
          header: item.offer.title,
          propertyName: item.offer.participatingProperties?.properties?.[0]?.basicInformation?.name,
          ctaUrl: item.offer.url,
          propertiesCount: item.offer.numProperties,
          memberLevel: item.offer.memberLevel,
          description: item.offer.description,
          catalogId: item.catalogId,
          parentOfferType: item.offer.parentOfferType,
        };
      });
    const updatedMoreCTALink = moreCTALink?.replace('{offerids}', concatenatedOfferIds);
    setMoreCTALink(updatedMoreCTALink);
    return { offersData: defaultOffers, isFallback, experienceId };
  };

  const isMemberExclusive = (memberLevel: Array<string>): boolean => {
    if (memberLevel?.find(ele => MEMBER_EXCLUSIVE.includes(ele))) {
      return true;
    }
    return false;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const processOfferCarousel = (data: any) => {
    const count = data?.offersData?.length;
    const cardKeys = Object?.keys(data?.offersData);
    setCardCount(count);
    setKeys(cardKeys);
    const offerMap = data?.offersData?.map((card: any, index: number) => {
      const imageDomain = envVarsObject?.['UXL_IMAGE_CACHE_DOMAIN'] ?? config.IMAGE_CACHE_DOMAIN;

      let imageUri = null;
      let imageUriLg = null;
      if (card.image.includes('/is/image')) {
        imageUri = `${imageDomain}${card.image}?wid=365&fit=constrain`;
        imageUriLg = `${imageDomain}${card.image}?wid=800&fit=constrain`;
      } else {
        imageUri = `${imageDomain}${card.image}?downsize=365px:*`;
        imageUriLg = `${imageDomain}${card.image}?downsize=800px:*`;
      }
      const eyeBrowText = card.propertiesCount === 1 ? card.propertyName : model?.eyebrow;

      const MemberExclusive = isMemberExclusive(card.memberLevel) ?? false;
      const badgeText = MemberExclusive ? model?.memberExclusive : '';
      const cardLocDetails = model?.trackingProperties?.location
        ? `${model.trackingProperties.location}-card-${index}`
        : `card-${index}`;
      const cardTrackingTag = model?.trackingProperties?.trackingTag
        ? `${model?.trackingProperties?.trackingTag}-${card.catalogId}`
        : card.catalogId || null;
      const cardTrackingOfferType = model?.trackingProperties?.trackingOfferType
        ? `${model?.trackingProperties?.trackingOfferType}-${card.parentOfferType}`
        : card.parentOfferType || null;
      const isCardVertical = cardsType === CARDSTYPE_VERTICAL;
      const cardObj = {
        ...(isCardVertical && {
          cardVerticalVariations: CardVerticalVariations.Tall1x1,
          ':type': 'mi-aem-common-spa/components/content/cardvertical',
          description: card.description,
          ctaLinkText: 'Read More',
        }),
        ...(!isCardVertical && {
          cardVerticalVariations: CardVerticalVariations.Standard,
          cardLayeredVariations: cardsType === LAYERED_WIDE ? CARD_LAYERED_WIDE : CARD_LAYERED_VARIATION,
          ':type': 'mi-aem-common-spa/components/content/cardlayered',
          description: card?.header,
          eyebrow: eyeBrowText,
        }),
      };

      return {
        ':items': {
          cardlayered_copy: {
            trackingProperties: {
              atCCeVar48: model?.trackingProperties?.atCCeVar48 || null,
              additionalTrackingVariables: model?.trackingProperties?.additionalTrackingVariables || null,
              trackingDescription: card?.header,
              description: card?.header,
              clickTrack: model?.trackingProperties?.clickTrack ? true : false,
              impressionTrack: false,
              location: cardLocDetails,
              trackingContentPosition: cardLocDetails,
              merchandisingCategory: model?.trackingProperties?.merchandisingCategory
                ? model.trackingProperties.merchandisingCategory
                : 'nonCobrand',
              impressionCount: false,
              trackingTag: cardTrackingTag,
              trackingOfferType: cardTrackingOfferType,
            },
            styleclass: model?.cardTheme,
            headerTag: 'h3',
            icon: '',
            impressionTrack: IMPRESSION_TRACK,
            badgeIcon: !isRecipeEliteExclusive ? 'icon-lock' : '',
            imgAltText: card?.header ? card.header : IMAGE_ALT_TEXT,
            clickTrack: model?.trackingProperties?.clickTrack ? true : false,
            fileReferenceImage: `${config.IMAGE_CACHE_DOMAIN}${card.image}`,
            header: card.header,
            fontSize: 't-subtitle-l',
            [cardsType === LAYERED_WIDE ? 'dynamicMediaWide' : 'dynamicMedia']: {
              altText: card?.header ? card.header : IMAGE_ALT_TEXT,
              assetPath: `${config.IMAGE_CACHE_DOMAIN}${card.image}`,
              dynamic: true,
              renditions: [
                {
                  renditionPath: imageUriLg,
                  mediaValue: '992px',
                  width: 0,
                  dynamic: true,
                  damPath: `${config.IMAGE_CACHE_DOMAIN}${card.image}`,
                  mediaQuery: 'min-width',
                  height: 0,
                },
                {
                  renditionPath: imageUri,
                  mediaValue: '576px',
                  width: 0,
                  dynamic: true,
                  damPath: `${config.IMAGE_CACHE_DOMAIN}${card.image}`,
                  mediaQuery: 'min-width',
                  height: 0,
                },
                {
                  renditionPath: imageUri,
                  mediaValue: '576px',
                  width: 0,
                  dynamic: true,
                  damPath: `${config.IMAGE_CACHE_DOMAIN}${card.image}`,
                  mediaQuery: 'max-width',
                  height: 0,
                },
              ],
              damPath: card.image,
            },
            merchandisingCategory: MERCHANDISING_CATEGORY,
            impressionCount: IMPRESSION_TRACK,
            ctaLink: addSubDirectoryPrefix(makeDomainSpecificContent(card.ctaUrl)),
            badgeText: badgeText,
            openInNewTab: openInaNewTab,
            ...cardObj,
          },
        },
      };
    });
    setResultOffer(offerMap);
  };

  const allowedComponents = [
    {
      path: '/jcr:content/root/responsivegrid/mi-aem-common-spa/components/content/cardlayered',
      title: 'Card Layered',
    },
    {
      path: '/jcr:content/root/responsivegrid/mi-aem-common-spa/components/content/cardvertical',
      title: 'Card Vertical',
    },
  ];

  const trackingProperties = {
    trackingContentPosition: model?.trackingProperties?.trackingContentPosition,
    trackingOfferType: model?.trackingProperties?.trackingOfferType,
    atCCeVar48: model?.trackingProperties?.atCCeVar48,
    trackingDescription: model?.trackingProperties?.trackingDescription,
    trackingTag: model?.trackingProperties?.trackingTag,
    isCoBrand: false,
    clickTrack: model?.trackingProperties?.clickTrack,
    impressionTrack: true,
    impressionCount: true,
    impressionEventType: model?.trackingProperties?.impressionEventType,
    merchandisingCategory: model?.trackingProperties?.merchandisingCategory,
    additionalTrackingVariables: model?.trackingProperties?.additionalTrackingVariables,
    location: model?.trackingProperties?.location,
    payloadType: model?.trackingProperties?.payloadType,
    compName: model?.trackingProperties?.compName,
    enableScrollingBehavior: model?.trackingProperties?.enableScrollingBehavior,
    cardLocation: model?.trackingProperties?.cardLocation,
    leftArrowDesc: model?.trackingProperties?.leftArrowDesc,
    rightArrowDesc: model?.trackingProperties?.rightArrowDesc,
    description: model?.trackingProperties?.description,
  };
  const carouselHeaderText = (text: any) => {
    if (defaultOffersReceipe === DESTINATION_OFFERS_RECIPE) {
      const updatedHeaderText = text?.replace('{Destination Name}', city);
      return updatedHeaderText;
    } else {
      return text;
    }
  };
  return (
    <StyledOfferCarouselContainer>
      <div>
        {!isDataLoading && !isLazyLoading ? (
          <div className={clsx(cardCount > 0 ? '' : 'hideCarousel', 'container cardWrapper')}>
            {offerResult?.length > 0 && (
              <CardCarouselContainer
                headerText={
                  !isMobileViewPort ? carouselHeaderText(model?.headerText) : carouselHeaderText(model?.mobileTitle)
                }
                subHeaderText={model?.subHeaderText}
                noOfCards={noOfCards}
                ctaLabel={model?.ctaLabel}
                ctaLink={addSubDirectoryPrefix(makeDomainSpecificContent(moreCTALink))}
                ctaType={model?.ctaType}
                cardCount={cardCount}
                openInaNewTab={openInaNewTab}
                totalNumberOfCards={keys}
                trackingProperties={trackingProperties}
                styleclass={model?.appliedCssClassNames}
                cqItems={offerResult}
                componentId={model?.componentId}
                pagePath={''}
                itemPath={''}
                wcmMode={model?.wcmMode}
                isAuthorMode={false}
                noOfCardsTablet={NO_OF_CARDS_TABLET}
                allowedComponents={allowedComponents}
                variation={variation ?? VARIATION_INVERSE}
              />
            )}
          </div>
        ) : (
          <div className="container">
            <OffersCardCarouselSkeletonLoader cardType={cardsType} numberOfCards={noOfCards} />
          </div>
        )}
      </div>
    </StyledOfferCarouselContainer>
  );
};

export default CardCarousel;
