import { FC, useEffect, useRef, useState } from 'react';

import { Heading } from '../../atoms/Heading';
import { Text } from '../../atoms/Text';
import { Button } from '../../atoms/Button';
import { Image } from '../../atoms/Image';
import { Eyebrow } from '../../atoms/Eyebrow';
import { headingType, tags, size } from '../../utils/enums/enums';
import { CardHorizontalFeatureProps, VideoDetails } from './CardHorizontalFeature.types';

import { StyledComp } from './CardHorizontalFeature.styles';
// import { ButtonStyle } from '../../utils/enums/enums';
import clsx from 'clsx';
import { Icon } from '../../atoms/Icon';
import { canUseDOM, VideoMolecule } from '@marriott/mi-ui-library';

/**
 * `CardHorizontalFeature` is a functional React component that renders a customizable card feature.
 *
 * Props:
 * - `eyebrowtext`: Text displayed at the top of the card.
 * - `header`: The main header text of the card.
 * - `description`: The main content or description of the card.
 * - `imageAlignment`: Determines the alignment of the image.
 * - `headerTag`: The HTML tag to be used for the header.
 * - `contentBlockAlignment`: Determines the alignment of the content block.
 * - `linkText`: The text for the link button.
 * - `linkUrl`: The URL that the link button points to.
 * - `ctaType`: The type of call-to-action button.
 * - `openInNewTab`: Boolean indicating whether the link should open in a new tab.
 * - `trackingProperties`: Object containing properties for tracking user interaction.
 * - `styleclass`: Custom class name for styling.
 * - `dynamicMedia`: Object containing information about the dynamic media.
 * - `headerFontSize`: The font size for the header.
 * - `descriptionCustomClass`: Custom class name for the description text.
 * - `headerCustomClass`: Custom class name for the header text.
 * - `custom_click_track_value`: Custom value for tracking click events.
 *
 * The component includes an image, a heading, text, and a button, all styled and configured according to the props.
 */

export const CardHorizontalFeature: FC<CardHorizontalFeatureProps> = ({
  eyebrowtext,
  header,
  description,
  imageAlignment,
  headerTag = tags.h2,
  contentBlockAlignment,
  linkText,
  linkUrl,
  ctaType,
  openInNewTab = false,
  trackingProperties,
  styleclass,
  dynamicMedia,
  headerFontSize = size.small,
  descriptionCustomClass,
  headerCustomClass,
  custom_click_track_value = '',
  logoIcon = 'LC',
  logoIconAltText,
  linkText2,
  ctaType2,
  linkUrl2,
  openInNewTab2,
  assetVariation,
  videosByLanguage,
  videoAccountId,
  mobileCoverImage,
  desktopCoverImage,
  dynamicMediaForDesktopCoverImage,
  dynamicMediaForMobileCoverImage,
  enableMute,
  enableVideoLoop,
  ...props
}) => {
  const [viewPort, setViewPort] = useState('Desktop');
  const isMobileView = viewPort === 'Mobile';
  const lang = canUseDOM ? document.documentElement.lang.replace('-', '_') : 'en_US';

  const handleResize = () => {
    const xs = window.matchMedia('(max-width: 767px)');
    const md = window.matchMedia('(min-width: 768px) and (max-width: 1199px)');
    const lg = window.matchMedia('(min-width: 1200px)');
    if (xs?.matches) {
      setViewPort('Mobile');
    } else if (md?.matches) {
      setViewPort('Tablet');
    } else if (lg?.matches) {
      setViewPort('Desktop');
    }
  };

  function getVideoByLanguage(videosByLanguage: VideoDetails[], lang: string) {
    const video = videosByLanguage.find(video => video?.language?.includes(lang));
    if (!video) {
      return videosByLanguage.find(video => video?.language?.includes('en_US'));
    }
    return video;
  }

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const renditions = dynamicMedia?.renditions;

  const primaryCTAisLink = linkText ? true : false;
  const secondaryCTAisLink = linkText2 ? true : false;

  function getCtaClasses(ctaType: string, openInNewTab: boolean) {
    let classNames = '';

    switch (ctaType) {
      case 'primaryButton':
        classNames += 'm-button-primary d-flex align-items-center';
        break;
      case 'secondaryButton':
        classNames += 'm-button-secondary d-flex align-items-center';
        break;
      case 'standaloneLink':
        classNames += 'm-link-action';
        break;
      case 'tertiaryLink':
        classNames += 'm-link-tertiary-button';
        break;
      default:
        break;
    }

    if (openInNewTab) {
      if (ctaType === 'primaryButton' || ctaType === 'secondaryButton') {
        classNames += ' m-button-external';
      } else {
        classNames += ' m-link-tertiary-button-external';
      }
    }

    return classNames.trim();
  }

  const textAlignClasses = contentBlockAlignment === 'center' ? 'text-center' : 'text-left';
  const video = getVideoByLanguage(videosByLanguage || [], lang);

  const customAttributes = trackingProperties?.enableScrollingBehavior
    ? { 'data-section-tracking': props?.componentId }
    : {};

  const primaryBtnRef = useRef(null) as unknown as React.MutableRefObject<HTMLInputElement>;

  const secondaryBtnRef = useRef(null) as unknown as React.MutableRefObject<HTMLInputElement>;

  return (
    <StyledComp
      data-component-name="m-ui-library-CardHorizontalFeature"
      data-testid="ui-library-CardHorizontalFeature"
      newTab={openInNewTab}
      alignElement={contentBlockAlignment}
      imgAlignment={imageAlignment}
      isMobileView={isMobileView}
      logoIcon={logoIcon}
      className={clsx(
        `${styleclass ? styleclass : ''}`,
        viewPort === 'Desktop' && assetVariation === 'video' && imageAlignment === 'right' ? 'flex-row-reverse' : ''
      )}
      {...customAttributes}
    >
      {assetVariation === 'video' ? (
        <div className={clsx('col-xs-12 col-md-6 col-xl-7 p-0 video-container')}>
          <VideoMolecule
            id={''}
            playerId={video?.playerID || ''}
            accountId={'6051814380001' || videoAccountId || ''}
            videoId={
              viewPort === 'Mobile' ? video?.brightcoveVideoIdMobile || '' : video?.brightcoveVideoIdDesktop || ''
            }
            poster={viewPort === 'Mobile' ? mobileCoverImage || '' : desktopCoverImage || ''}
            enableMute={enableMute}
            enableVideoLoop={enableVideoLoop}
            renditions={
              viewPort === 'Mobile'
                ? dynamicMediaForMobileCoverImage?.renditions || []
                : dynamicMediaForDesktopCoverImage?.renditions || []
            }
          />
        </div>
      ) : (
        <div className={clsx('col-xs-12 col-md-6 col-xl-7 p-0 img-container', { 'gradient-color': logoIcon })}>
          <Image
            customClass="feature-img"
            altText={dynamicMedia?.altText}
            renditions={renditions}
            dynamic={dynamicMedia?.dynamic}
            defaultImageURL={dynamicMedia?.assetPath}
          />
          {logoIcon && <Icon iconClass={clsx(`brand-logo-${logoIcon}`, 'icon-inverse')} ariaLabel={logoIconAltText} />}
        </div>
      )}

      <div className={`content ${textAlignClasses} col-xs-12 col-md-6 col-xl-5 t-background-color`}>
        {eyebrowtext && <Eyebrow text={eyebrowtext} />}
        <Heading
          customClass={clsx('feature-header', headerCustomClass)}
          titleText={header}
          variation={headingType.title}
          fontSize={headerFontSize}
          element={headerTag}
        />
        {description && (
          <Text
            customClass={clsx('feature-text t-font-alt-s', descriptionCustomClass)}
            copyText={description}
            fontSize={size.small}
            element={tags.div}
          />
        )}
        <div className="btn-section">
          {linkUrl && linkText && (
            <Button
              isLink={primaryCTAisLink}
              href={linkUrl}
              className={getCtaClasses(ctaType as string, openInNewTab)}
              target={openInNewTab === true ? '_blank' : '_self'}
              trackingProperties={trackingProperties}
              ariaLabel={linkText}
              custom_click_track_value={custom_click_track_value}
              ref={primaryBtnRef}
            >
              {linkText}
            </Button>
          )}
          {linkUrl2 && linkText2 && (
            <Button
              isLink={secondaryCTAisLink}
              href={linkUrl2}
              className={getCtaClasses(ctaType2 as string, openInNewTab2 as boolean)}
              target={openInNewTab2 === true ? '_blank' : '_self'}
              trackingProperties={trackingProperties}
              ariaLabel={linkText2}
              custom_click_track_value={custom_click_track_value}
              ref={secondaryBtnRef}
            >
              {linkText2}
            </Button>
          )}
        </div>
      </div>
    </StyledComp>
  );
};
