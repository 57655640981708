// Imports for external libraries go here.
import { FC } from 'react';
import { Link, TrackingPropsInterface } from '@marriott/mi-ui-library';
import { EditableComponent } from '@adobe/aem-react-editable-components';

// Imports for internal (to the monorepo) libraries go here,
// separated by a blank line from external imports.
// The closer the import is to the file the lower it should be in this list.
import { LanguageSelector } from '../Navigation/LanguageSelector';
import { StyledContainer, StyledFooterContainer, StyledP, LanguageSelectorContainer } from './Footer.styles';
import { socialLinks } from './Footer.types';
import { getClickTrackValue, DEFAULT_TRACKING_VALUES } from '../../utils';
import { FooterProps } from './Footer.types';
import { ExpandedFooter } from './ExpandedFooter';
import { CollapsedFooter } from './CollapsedFooter';

// Use named rather than default exports.
export const FooterComp: FC<FooterProps> = (props: FooterProps) => {
  const { model } = props;

  const trackingPropertiesObject: {
    trackingProperties: TrackingPropsInterface;
    fallbacks: { position: string; description: string };
  } = {
    trackingProperties: {
      trackingContentPosition: model?.trackingProperties?.trackingContentPosition,
      clickTrack: model?.trackingProperties?.clickTrack,
      trackingOfferType: model?.trackingProperties?.trackingOfferType,
      impressionCount: model?.trackingProperties?.impressionCount,
      impressionTrack: model?.trackingProperties?.impressionTrack,
      trackingDescription: model?.trackingProperties?.trackingDescription,
      trackingTag: model?.trackingProperties?.trackingTag,
    },
    fallbacks: {
      position: model?.trackingProperties?.location ?? DEFAULT_TRACKING_VALUES.LABEL_NULLISH,
      description: model?.trackingProperties?.trackingDescription ?? DEFAULT_TRACKING_VALUES.LABEL_NULLISH,
    },
  };

  return (
    <StyledContainer className="container">
      {model?.resourceProperties?.variation === 'expanded' ? (
        <ExpandedFooter model={model} />
      ) : (
        <CollapsedFooter model={model} />
      )}
      <div className="m-accordion-container inverse">
        {model?.socialItems?.length >= 1 && (
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <div className="accordion-button pb-md-2">
                <div className="m-accordion-title flex-column flex-md-row">
                  <StyledP className="t-font-l mr-4 mb-3">{model?.resourceProperties.socialTitle}</StyledP>
                  <div className="d-flex">
                    {model?.socialItems?.map((socialItem, index) => {
                      return (
                        <p className="mr-5" key={socialItem.linkLabel}>
                          <Link
                            linkClassName="t-font-alt-md"
                            linkHref={socialItem.linkURL as string}
                            custom_click_track_value={getClickTrackValue(
                              trackingPropertiesObject,
                              socialItem.linkLabel
                            )}
                            trackingProperties={trackingPropertiesObject.trackingProperties}
                          >
                            <span className={`icon-${socialLinks[index]} icon-inverse`} />
                          </Link>
                        </p>
                      );
                    })}
                  </div>
                </div>
              </div>
            </h2>
          </div>
        )}
        <div className="accordion-button d-flex flex-column flex-md-row align-items-start justify-content-around add-border z-3">
          <p
            className="m-accordion-title t-font-alt-xs align-self-center m-0"
            dangerouslySetInnerHTML={{ __html: model?.resourceProperties?.copyrightDescription }}
          />
          <LanguageSelectorContainer>
            <LanguageSelector
              buttonClassName="footer-language-selector"
              renderUtilityLink={() => (
                <div className="d-flex flex-row align-items-center mt-4 mt-md-0 inverse">
                  <span className="icon-globe icon-inverse mr-1" />{' '}
                  <p className="t-font-alt-xs m-0">{model?.resourceProperties?.languageLable}</p>
                </div>
              )}
              restProps={{
                trackingContentPosition: model?.trackingProperties?.trackingContentPosition,
                'cq:panelTitle': model?.resourceProperties?.languageLable,
                resourceProperties: model?.resourceProperties,
                trackingProperties: trackingPropertiesObject?.trackingProperties,
                regions: model?.regions,
              }}
            />
          </LanguageSelectorContainer>
        </div>
        <div className="row">
          <div className="js-session-detail t-label-inverse-alt-xs" aria-label="Session Details"></div>
        </div>
      </div>
    </StyledContainer>
  );
};

/* eslint-disable  @typescript-eslint/no-explicit-any */
export const Footer = (props: any) => {
  const FooterConfig = {
    emptyLabel: 'Footer',
    isEmpty: () => true,
    resourceType: `mi-aem-homepage-spa/components/content/universal-footer`,
    id: '',
  };

  return (
    <div id="universal-footer" data-component-name="m-common-static-Footer" data-testid="common-static-Footer">
      {props.cqPath?.includes('experiencefragment') ? (
        <StyledFooterContainer className="m-container-fullbleed">
          <FooterComp {...props} />
        </StyledFooterContainer>
      ) : (
        <EditableComponent {...props} config={FooterConfig}>
          <StyledFooterContainer className="m-container-fullbleed">
            <FooterComp {...props} />
          </StyledFooterContainer>
        </EditableComponent>
      )}
    </div>
  );
};
