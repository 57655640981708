import styled from 'styled-components';
import { baseVariables, toRem } from '../../styles';

export const StyledVideoPlayer = styled.div`
  .video-image-container {
    position: relative;
  }
  img {
    max-width: -webkit-fill-available;
  }
  .video-image-btn-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .video-image-play-btn {
    background: transparent;
  }
`;

export const StyledVideoModal = styled.div`
  [class^='vjs-']::before {
    font-family: 'VideoJS';
  }
  .video-modal-container {
    max-height: calc(100vh - 32px);
    min-height: auto;
    height: auto;
    max-width: calc(177.77vh - 64px);
    width: 80vw;
    aspect-ratio: 16 / 9;
    background-color: ${baseVariables.color.base10};
  }
  .video-modal-container-mobile {
    width: 100%;
    max-width: 100%;
    height: 100vh;
    max-height: 100vh;
    background: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    aspect-ratio: 16 / 9;
    border-radius: unset;
    margin: auto;
    min-height: -webkit-fill-available;
  }
  .video-modal-header {
    position: absolute;
    z-index: 99;
    border: none;
    padding: 0;
    height: auto;
    top: ${toRem(10)};
    right: ${toRem(10)};
  }
  .video-close-btn {
    font-size: ${toRem(24)};
    line-height: ${toRem(24)};
  }
  .video-modal-body {
    overflow: visible;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media ${baseVariables.mediaQuery.xl} {
      display: block;
    }
  }
  .video-modal-main-container {
    @media ${baseVariables.mediaQuery.xl} {
      display: flex;
    }
  }
  .vjs-control-bar {
    position: fixed;
    @media ${baseVariables.mediaQuery.xl} {
      position: absolute;
    }
  }
  .vjs-progress-control {
    height: ${toRem(4.19)};
    top: 0;
  }
`;
