import {
  CarouselElementContainer,
  CarouselContainer,
  FlexContainer,
  LayeredContainer,
} from './OffersCardCarouselSkeletonLoader.styles';
import {
  CARDSTYPE_LAYERED,
  CARDSTYPE_VERTICAL,
  CARD_SQUARE,
  LAYERED_WIDE,
} from '../../../constants/OfferCardConstants';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const OffersCardCarouselSkeletonLoader = (props: any) => {
  const noOfCards = props?.numberOfCards;
  const cardType = props?.cardType;
  const getCardClass = (type: string, cardCount: number) => {
    if (type === LAYERED_WIDE && cardCount === 2) {
      return 'skeleton-loader skeleton-loader-composite wide-2';
    } else if (type === CARD_SQUARE && cardCount === 3) {
      return 'skeleton-loader skeleton-loader-composite square-3';
    } else if (type === CARD_SQUARE && cardCount === 4) {
      return 'skeleton-loader skeleton-loader-composite square-4';
    } else {
      return '';
    }
  };
  return cardType !== CARDSTYPE_LAYERED && cardType !== CARDSTYPE_VERTICAL ? (
    <CarouselContainer data-testid="carousel-container-skeleton-loader">
      <FlexContainer>
        <div className="skeleton-loader carousel-title"></div>
        <div className="skeleton-loader carousel-cta"></div>
      </FlexContainer>
      <CarouselElementContainer>
        {Array.from({ length: noOfCards }, (_, index) => (
          <div key={index} className="standard-container">
            <div className={getCardClass(props?.cardType, noOfCards)}></div>
          </div>
        ))}
      </CarouselElementContainer>
    </CarouselContainer>
  ) : (
    <LayeredContainer data-testid="combo-carousel-container-skeleton-loader">
      <div className="text-content">
        <div className="skeleton-loader combo-carousel-title"> </div>
        <div className="skeleton-loader combo-carousel-cta"></div>
      </div>
      <div className="image-content">
        {Array.from({ length: noOfCards }, (_, index) => (
          <div
            key={index}
            className={props?.cardType === CARDSTYPE_LAYERED ? 'image-wrapper' : 'image-wrapper-vertical'}
          >
            <div className="skeleton-loader skeleton-loader-combo"></div>
          </div>
        ))}
      </div>
    </LayeredContainer>
  );
};
