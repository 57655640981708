import { forwardRef, useEffect } from 'react';
import clsx from 'clsx';

import { ButtonProps } from './Button.types';

import { getClickTrackValue, trackImpression } from '../../utils/TrackingPropertyUtils';

import { constants } from '../../constants/ApplicationConstants';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Button = forwardRef((props: ButtonProps, ref?: any) => {
  const {
    type = 'button',
    buttonCopy = '',
    style,
    className,
    callback,
    isLink = false,
    href,
    target,
    rel,
    children,
    trackingProperties,
    linkType = 'external',
    isDisabled = false,
    testId = '',
    isTextBeforeChildren = true,
    compName,
    custom_click_track_value,
    linkAriaLabelOpenNewWindow = 'opens in new window',
    ariaExpanded = undefined,
    ariaLabel = '',
    ariaRole,
    title,
  } = props;

  const { COBRAND_NON_POINTS_MATH, TARGET_BLANK } = constants;
  const trackingProps = {
    ...trackingProperties,
    isCoBrand: trackingProperties?.type
      ? trackingProperties?.type === 'CoBrand'
      : trackingProperties?.merchandisingCategory === COBRAND_NON_POINTS_MATH,
  };
  useEffect(() => {
    if (!ref?.current?.classList?.contains('track-completed')) {
      if (!custom_click_track_value) {
        getClickTrackValue(linkType, trackingProps, ref);
      }
      if (trackingProps?.impressionTrack && !trackingProps?.clickTrackValSeparator)
        trackImpression(trackingProps || {}, buttonCopy, props?.preTrackVal);
      ref?.current?.classList?.add('track-completed');
    }
  }, []);

  let ariaLabelCopy = target === TARGET_BLANK ? `${buttonCopy} ${linkAriaLabelOpenNewWindow}` : buttonCopy;
  ariaLabelCopy = ariaLabelCopy && ariaLabelCopy?.trim();

  const renderLink = () => {
    return (
      <a
        data-component-name="a-ui-library-Button"
        data-testid={testId}
        id={props?.id}
        href={href}
        onClick={callback}
        target={target}
        className={clsx(className, 'custom_click_track')}
        rel={rel}
        ref={ref}
        data-tracking-content-position={trackingProps?.trackingContentPosition}
        data-tracking-offer-type={trackingProps?.trackingOfferType}
        data-at-c-ce-var48={trackingProps?.atCCeVar48}
        data-tracking-description={trackingProps?.trackingDescription}
        data-tracking-tag={trackingProps?.trackingTag}
        data-comp-name={compName}
        data-custom_click_track_value={custom_click_track_value}
        data-is-co-brand={trackingProps?.isCoBrand}
        data-is-click-track={trackingProps?.clickTrack}
        data-is-impression-track={trackingProps?.impressionTrack}
        data-is-impression-count={trackingProps?.impressionCount}
        data-additional-tracking-variables={trackingProps?.additionalTrackingVariables}
        aria-label={ariaLabelCopy || ariaLabel}
        aria-expanded={ariaExpanded}
        data-is-auth={trackingProps?.isAuth}
        role={ariaRole}
        title={title}
      >
        {isTextBeforeChildren && `${buttonCopy}`}
        {children}
        {!isTextBeforeChildren && `${buttonCopy}`}
      </a>
    );
  };

  return isLink ? (
    renderLink()
  ) : (
    <button
      data-component-name="a-ui-library-Button"
      data-testid={testId}
      id={props?.id}
      disabled={isDisabled}
      ref={ref}
      type={type}
      style={style}
      className={clsx(className, 'custom_click_track')}
      onClick={callback}
      aria-label={ariaLabelCopy || ariaLabel}
      data-tracking-content-position={trackingProps?.trackingContentPosition}
      data-tracking-offer-type={trackingProps?.trackingOfferType}
      data-at-c-ce-var48={trackingProps?.atCCeVar48}
      data-tracking-description={trackingProps?.trackingDescription}
      data-tracking-tag={trackingProps?.trackingTag}
      data-is-co-brand={trackingProps?.isCoBrand}
      data-is-click-track={trackingProps?.clickTrack}
      data-custom_click_track_value={custom_click_track_value}
      data-is-impression-track={trackingProps?.impressionTrack}
      data-is-impression-count={trackingProps?.impressionCount}
      data-additional-tracking-variables={trackingProps?.additionalTrackingVariables}
      aria-expanded={ariaExpanded}
      data-is-auth={trackingProps?.isAuth}
      title={title}
    >
      {isTextBeforeChildren && `${buttonCopy}`}
      {children}
      {!isTextBeforeChildren && `${buttonCopy}`}
    </button>
  );
});
