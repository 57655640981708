// Styles for SkeletonLoader go here.
import styled from 'styled-components';
import { baseVariables, toRem } from '@marriott/mi-ui-library';

export const CarouselContainer = styled.div`
  margin: ${toRem(55)} 0;
`;

export const CarouselElementContainer = styled.div`
  display: flex;
  flex-direction: row;
  .standard-container:last-child .wide-2 {
    @media (max-width: 767px) {
      display: none;
    }
  }
  .wide-2 {
    height: ${toRem(174)};
    width: ${toRem(337)};
    margin-left: 0.625rem;
    @media (min-width: 350px) {
      height: ${toRem(174)};
      width: ${toRem(337)};
      margin-left: 0.625rem;
    }
    @media (min-width: 360px) {
      height: ${toRem(174)};
      width: ${toRem(315)};
      margin-left: 0.625rem;
      margin-right: 1.5rem;
    }
    @media (min-width: 390px) {
      height: ${toRem(174)};
      width: ${toRem(337)};
      margin-right: 1rem;
      margin-left: 0.625rem;
    }
    @media (min-width: 412px) {
      height: ${toRem(174)};
      width: ${toRem(337)};
      margin-left: 1.625rem;
      margin-right: 1rem;
    }
    @media (min-width: 430px) {
      height: ${toRem(200)};
      width: ${toRem(387)};
      margin-right: 1.5rem;
      margin-left: 6px;
    }
    @media ${baseVariables.mediaQuery.md} {
      height: ${toRem(184)};
      width: ${toRem(336)};
    }
    @media (min-width: 1024px) {
      height: ${toRem(252)};
      width: ${toRem(458)};
    }
    @media ${baseVariables.mediaQuery.xl} {
      height: ${toRem(302)};
      width: ${toRem(537)};
      display: block !important;
    }
  }
  .standard-container:nth-last-of-type(-n + 2) .square-3 {
    @media (min-width: 400px) and (max-width: 767px) {
      display: none;
    }
  }
  .standard-container:last-child .square-3 {
    @media (min-width: 768px) {
      display: none;
    }
  }
  .standard-container:last-child .square-3 {
    @media (min-width: 912px) {
      display: none;
    }
  }
  .square-3 {
    height: ${toRem(344)};
    width: ${toRem(344)};
    @media (min-width: 350px) {
      height: ${toRem(344)};
      width: ${toRem(344)};
      margin-right: 3.938rem;
      margin-left: 0px;
    }
    @media (min-width: 360px) {
      height: ${toRem(328)};
      width: ${toRem(328)};
      margin-right: 3.938rem;
      margin-left: 0px;
    }
    @media (min-width: 390px) {
      height: ${toRem(344)};
      width: ${toRem(344)};
      margin-right: 2rem;
      margin-left: 1rem;
    }
    @media (min-width: 412px) {
      height: ${toRem(344)};
      width: ${toRem(344)};
      margin-right: 2rem;
      margin-left: 1.5rem;
    }
    @media (min-width: 430px) {
      height: ${toRem(365)};
      width: ${toRem(365)};
      margin-right: 2.938rem;
      margin-left: 1rem;
    }
    @media ${baseVariables.mediaQuery.md} {
      height: ${toRem(328)};
      width: ${toRem(328)};
      margin-right: 3.25rem;
      margin-left: 0px;
    }
    @media (min-width: 1024px) {
      height: ${toRem(448)};
      width: ${toRem(448)};
      margin-right: 2.5rem;
      margin-left: 0px;
    }
    @media ${baseVariables.mediaQuery.xl} {
      height: ${toRem(353)};
      width: ${toRem(353)};
      margin-right: 1.5rem;
      display: block !important;
    }
  }
  .standard-container:nth-last-of-type(-n + 3) .square-4 {
    @media (min-width: 400px) and (max-width: 767px) {
      display: none;
    }
  }
  .standard-container:nth-last-of-type(-n + 2) .square-4 {
    @media (min-width: 768px) {
      display: none;
    }
  }
  .standard-container:nth-last-of-type(-n + 2) .square-4 {
    @media (min-width: 912px) {
      display: none;
    }
  }
  .square-4 {
    height: ${toRem(344)};
    width: ${toRem(344)};
    @media (min-width: 350px) {
      height: ${toRem(344)};
      width: ${toRem(344)};
      margin-right: 3.938rem;
      margin-left: 0px;
    }
    @media (min-width: 360px) {
      height: ${toRem(328)};
      width: ${toRem(328)};
      margin-right: 3.938rem;
      margin-left: 0px;
    }
    @media (min-width: 390px) {
      height: ${toRem(344)};
      width: ${toRem(344)};
      margin-right: 2rem;
      margin-left: 1rem;
    }
    @media (min-width: 412px) {
      height: ${toRem(344)};
      width: ${toRem(344)};
      margin-right: 2rem;
      margin-left: 1.5rem;
    }
    @media (min-width: 430px) {
      height: ${toRem(365)};
      width: ${toRem(365)};
      margin-right: 2.938rem;
      margin-left: 1rem;
    }
    @media ${baseVariables.mediaQuery.md} {
      height: ${toRem(328)};
      width: ${toRem(328)};
      margin-right: 3.063rem;
      margin-left: 0px;
    }
    @media (min-width: 1024px) {
      height: ${toRem(448)};
      width: ${toRem(448)};
      margin-right: 2.5rem;
      margin-left: 0px;
    }
    @media ${baseVariables.mediaQuery.xl} {
      height: ${toRem(261)};
      width: ${toRem(261)};
      margin-right: 1.5rem;
      display: block !important;
    }
  }
  .skeleton-loader-composite {
    background-color: ${baseVariables.color['neutral20']};
    color: white;
    border-radius: 0.9375rem;
  }
`;

export const LayeredContainer = styled.div`
  @media ${baseVariables.mediaQuery.xl} {
    display: flex;
    flex-wrap: wrap;
    margin-right: 20px;
    width: 103%;
  }
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
  }
  .text-content {
    flex: 1;
    padding: 20px;
    margin-top: 10%;
    @media (max-width: 767px) {
      margin-top: 0;
      width: 100%;
      text-align: center;
    }
    .combo-carousel-title {
      background-color: ${baseVariables.color['neutral20']};
      margin-bottom: ${toRem(16)};
      flex: 1;
      padding: 20px;
    }
    .combo-carousel-cta {
      background-color: ${baseVariables.color['neutral20']};
      height: ${toRem(16)};
      padding: 12px;
      flex: 1;
      width: 40%;
    }
  }
  .image-content {
    flex: 2;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 15px;
    @media (max-width: 767px) {
      flex-direction: column;
      width: 100%;
      text-align: center;
    }
  }
  .image-wrapper {
    position: relative;
    width: 50%;
    padding-top: 45%;
    margin: 10px;
    overflow: hidden;
    &:nth-child(2) {
      @media (max-width: 767px) {
        width: 0;
        height: 0;
        margin: 0;
        overflow: hidden;
        display: none;
      }
    }
    @media (max-width: 767px) {
      padding-top: 100%;
      width: 100%;
      margin: 10px 0;
    }
  }
  .image-wrapper-vertical {
    position: relative;
    width: 45%;
    height: 35rem;
    margin: 10px;
    overflow: hidden;
    @media ${baseVariables.mediaQuery.xl} {
      height: 28rem;
    }
    &:nth-child(2) {
      @media (max-width: 767px) {
        width: 0;
        height: 0;
        margin: 0;
        overflow: hidden;
        display: none;
      }
    }
    @media (max-width: 767px) {
      height: 35rem;
      width: 100%;
      margin: 10px 0;
    }
  }
  .skeleton-loader-combo {
    background-color: ${baseVariables.color['neutral20']};
    color: white;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 0.9375rem;
  }
`;
export const FlexContainer = styled.div`
  display: flex;
  margin-bottom: ${toRem(16)};
  align-items: baseline;
  justify-content: space-between;
  flex-direction: row;
  .carousel-title {
    background-color: ${baseVariables.color['neutral20']};
    height: ${toRem(28)};
    margin-bottom: ${toRem(16)};
    width: 60%;
    @media ${baseVariables.mediaQuery.xl} {
      width: ${toRem(350)};
      height: ${toRem(32)};
    }
  }
  .carousel-cta {
    display: flex;
    align-items: flex-end;
    margin-right: 0.25rem;
    text-wrap: nowrap;
    background-color: ${baseVariables.color['neutral20']};
    height: ${toRem(16)};
    width: ${toRem(100)};

    @media ${baseVariables.mediaQuery.xl} {
      width: ${toRem(100)};
      height: ${toRem(16)};
    }
  }
`;
