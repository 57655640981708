import moment, { Moment } from 'moment';
import { isCNLocale, logger } from '@marriott/shared/mi-helper-utils';
const { log } = logger({})('DateUtils');
export type DateObjectType = Moment;

export const DATE_FORMAT_YEAR_MONTH_DAY = 'YYYY-MM-DD';
const DATE_FORMAT_DEFAULT = 'ddd, MMM DD';
export const DATE_FORMAT_VALUE = 'MM/DD/YYYY';

export const getFormattedDate = (date: string, format = DATE_FORMAT_YEAR_MONTH_DAY): DateObjectType => {
  return moment(moment(date).format(format));
};

export const getDurationBetweenDates = (startDate: string, endDate: string): number => {
  return moment.duration(getFormattedDate(endDate).diff(getFormattedDate(startDate), 'days'), 'days').asDays();
};

export const DiffBtDates = (val: string): number => {
  // Input Format should be YYYY-DD-MM
  const date1 = moment(new Date(val));
  const date2 = moment(new Date(new Date().toISOString().substr(0, 10)));
  const diffDays = moment.duration(date1.diff(date2)).asDays();
  return diffDays;
};

export const getDateFormatted = (date?: DateObjectType, format?: string): string => {
  return date?.format(format ?? 'MM/DD/YYYY') ?? '';
};

export const getMPOFormattedDate = (): { currMPODate: string; nextMPODate: string } => {
  const dateObj = {
    currMPODate: getDateFormatted(moment(new Date()), DATE_FORMAT_YEAR_MONTH_DAY).toString(),
    nextMPODate: getDateFormatted(moment(new Date()).add(1, 'days'), DATE_FORMAT_YEAR_MONTH_DAY).toString(),
  };
  return dateObj;
};

export const getCurrentDateObject = (): DateObjectType => {
  return moment();
};

export const getValueFromDate = (date?: DateObjectType, format?: string): string => {
  return date?.format(format ?? DATE_FORMAT_VALUE) ?? '';
};

export const getNextDateObject = (date: DateObjectType): DateObjectType => {
  return moment(date).add(1, 'days');
};

export const getPrevDateObject = (date: DateObjectType): DateObjectType => {
  return moment(date).add(-1, 'days');
};

export const getDateObject = (date: string | Date, format = DATE_FORMAT_YEAR_MONTH_DAY): DateObjectType => {
  return moment(date, format).utc();
};

export const getDateObj = (date: string | Date, format = DATE_FORMAT_YEAR_MONTH_DAY): DateObjectType => {
  return moment(date, format);
};

//get current date object
export const getDateObjectCurrent = (date: string, format = DATE_FORMAT_YEAR_MONTH_DAY): DateObjectType => {
  moment.locale('locale');
  return moment(date, format).utc(true);
};

export const setMomentLocale = (locale: string) => {
  try {
    let momentLocale = locale;
    switch (locale) {
      case 'de-de':
        require(`moment/locale/de`);
        momentLocale = 'de';
        break;
      case 'ja-jp':
        require(`moment/locale/ja`);
        momentLocale = 'ja';
        break;
      case 'ko-kr':
        require(`moment/locale/ko`);
        momentLocale = 'ko';
        break;
      case 'es-es':
        require(`moment/locale/es`);
        momentLocale = 'es';
        break;
      case 'it-it':
        require(`moment/locale/it`);
        momentLocale = 'it';
        break;
      case 'fr-fr':
        require(`moment/locale/fr`);
        momentLocale = 'fr';
        break;
      case 'en-gb':
        require(`moment/locale/en-gb`);
        momentLocale = 'en-gb';
        break;
      case 'pt-br':
        require(`moment/locale/pt-br`);
        momentLocale = 'pt-br';
        break;
      case 'ru-ru':
        require(`moment/locale/ru`);
        momentLocale = 'ru';
        break;
    }
    moment.locale(momentLocale);
  } catch (e) {
    log.warn('Failed to load moment locale package');
  }
};

// to get the dates based on the localization
export const getLocalizedDate = (
  date: DateObjectType | null | undefined,
  dateFormat?: string,
  locale?: string
): string => {
  // CNWEB-2528 Add a check to load the zh-cn locale to get correct localized date
  if (locale) {
    if (isCNLocale(locale)) {
      try {
        require(`moment/locale/zh-cn`);
        moment.locale('zh-cn');
      } catch (e) {
        log.warn('Failed to load locale - zh-cn');
      }
    } else {
      locale = locale.replace('_', '-')?.toLowerCase();
      setMomentLocale(locale);
    }
  } else {
    moment.locale('locale');
  }
  if (date) {
    //initially date will be in the english format we need to change that to the localized value
    const formattedDate = moment(date).format();
    return moment(formattedDate).format(dateFormat ?? DATE_FORMAT_DEFAULT);
  }
  return '';
};

// to get total number of days diffrence between start date and end date
export const getTotalNumberOfDays = (
  startDate: DateObjectType | undefined | null,
  endData: DateObjectType | undefined | null
): number => {
  moment.locale('locale');
  if (startDate && endData) {
    return Math.abs(moment.duration(startDate.diff(endData)).asDays() ?? 1);
  }
  return 1;
};
