import styled from 'styled-components';
import { baseVariables, toRem } from '../../styles';
// import { toRem } from '../../index';

export const StyledBannerLayered = styled.div<{ scrimGradient: string }>`
  display: flex;
  .bl-item {
    img {
      width: 100vw;
    }
    position: relative;
    overflow: hidden;
    .gradient {
      &:after {
        height: 60%;
        background: ${props =>
          props?.scrimGradient?.includes('inverse')
            ? `${baseVariables.gradient['baseGradient05']}`
            : `${baseVariables.gradient['baseGradient01']}`};
        @media ${baseVariables.mediaQuery.sm} {
          height: 100%;
          background: ${props =>
            props?.scrimGradient?.includes('inverse')
              ? `${baseVariables.gradient['baseGradient06']}`
              : `${baseVariables.gradient['baseGradient02']}`};
        }
        @media ${baseVariables.mediaQuery.lg} {
          height: 100%;
          background: ${props =>
            props?.scrimGradient?.includes('inverse')
              ? `${baseVariables.gradient['baseGradient07']}`
              : `${baseVariables.gradient['baseGradient03']}`};
        }
        display: block;
        width: 100%;
        position: absolute;
        content: '';
        bottom: 0;
        left: 0;
        right: 0;
      }
    }
    .bl_iconText-wrapper {
      position: absolute;
      padding: ${toRem(3)} ${toRem(8)};
      background-color: rgba(28, 28, 28, ${baseVariables.opacity['opacity5']});
      border-radius: ${toRem(5)};
      right: ${toRem(30)};
      bottom: ${toRem(12)};
      @media ${baseVariables.mediaQuery.sm} {
        right: ${toRem(15)};
        bottom: ${toRem(13)};
      }
      @media ${baseVariables.mediaQuery.lg} {
        right: ${toRem(30)};
        bottom: ${toRem(20)};
      }
      .banner-layerd-anchor {
        padding-left: ${toRem(8)};
      }
    }
    .bl_iconText-wrapper.top-right {
      padding: ${toRem(4)};
      border-radius: ${toRem(4)};
      right: ${toRem(16)};
      top: ${toRem(16)};
      bottom: auto;
      .banner-layerd-anchor {
        padding-left: ${toRem(4)};
      }
    }
  }
  .bl {
    &__cnt-sec {
      position: absolute;
      display: flex;
      align-items: end;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      &:before {
        display: none;
      }
      .bl_content {
        .bl_head {
          margin-bottom: ${toRem(0)};
        }
        @media ${baseVariables.mediaQuery.xl} {
          .bl_head {
            margin-bottom: ${toRem(4)};
          }
        }
      }
      .bl_cta-wrapper {
        display: flex;
        flex-direction: column;
        margin-top: ${toRem(4)};
        text-align: center;
        @media ${baseVariables.mediaQuery.sm} {
          flex-direction: row;
          margin-top: -${toRem(8)};
        }
        @media ${baseVariables.mediaQuery.lg} {
          margin-top: ${toRem(10)};
        }
        .bl_sec-cta {
          margin-left: ${toRem(0)};
          margin-top: ${toRem(10)};
          @media ${baseVariables.mediaQuery.sm} {
            margin-left: ${toRem(16)};
            margin-top: ${toRem(0)};
          }
        }
        .m-link-tertiary-button {
          margin-top: -${toRem(12)};
          text-align: left;
          @media ${baseVariables.mediaQuery.sm} {
            margin-top: ${toRem(16)};
          }
          @media ${baseVariables.mediaQuery.lg} {
            margin-top: ${toRem(0)};
          }
        }
      }
      .&_cta-center {
        justify-content: center;
      }
      @media ${baseVariables.mediaQuery.sm} {
        align-items: center;
      }
    }
  }
  .bl__cnt-ctr {
    position: relative;
    z-index: 1;
    padding: 0 ${toRem(16)} ${toRem(70)};
    margin: 0 auto;
    @media ${baseVariables.mediaQuery.sm} {
      padding-bottom: ${toRem(0)};
    }
  }
`;
