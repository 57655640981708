/* eslint-disable @typescript-eslint/no-explicit-any */
// Styles for RoomsandGuests go here.
import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

export const StyledRoomsAndGuests = styled.div<{ $isTabbedSearchForm?: boolean }>`
  cursor: default;
  min-width: ${toRem(151)};
  .m-dropdown-action-bar {
    position: fixed !important;
    bottom: ${toRem(-6)} !important;
    background: ${baseVariables.color['base20']} !important;
    padding-right: ${toRem(24)} !important;
    padding-left: ${toRem(24)} !important;
    @media ${baseVariables.mediaQuery.md} {
      padding-right: ${toRem(32)} !important;
      padding-left: ${toRem(32)} !important;
    }
  }
  @media ${baseVariables.mediaQuery.md} {
    .icon-clear {
      padding-right: ${toRem(8)};
    }
  }

  .m-dropdown-content {
    &::-webkit-scrollbar {
      display: none;
      @media ${baseVariables.mediaQuery.lg} {
        display: block;
      }
    }
  }
  .dropdown-container {
    margin: 0 auto;
  }
  .m-input-text-field {
    label,
    input {
      cursor: pointer;
    }
  }
  .rooms-guests-m-font {
    font-weight: 700 !important;
    padding-top: ${props => (props.$isTabbedSearchForm ? `${toRem(20)} !important` : 'inherit')};
    padding-bottom: ${props => (props.$isTabbedSearchForm ? `${toRem(9)} !important` : 'inherit')};
  }
  .icon-arrow-down {
    &:before {
      line-height: ${props => (props.$isTabbedSearchForm ? `${toRem(16)}` : 'inherit')};
    }
  }
  .header-heading {
    height: ${props => (props.$isTabbedSearchForm ? `${toRem(28)}` : 'inherit')};
  }
  .t-font-s {
    height: ${props => (props.$isTabbedSearchForm ? `${toRem(21)}` : 'inherit')};
  }
  .m-stepper-desc {
    line-height: ${props => (props.$isTabbedSearchForm ? `${toRem(18)} !important ` : 'inherit')};
  }
  .m-link-action {
    display: 'inline-block';
    height: ${props => (props.$isTabbedSearchForm ? `${toRem(18)}` : 'inherit')};
  }
  .m-stepper-wrapper .m-stepper > span {
    padding: 0;
  }
  .m-dropdown-children {
    position: relative;
    padding: ${toRem(8)} ${toRem(0)} ${toRem(8.5)} ${toRem(0)};
    .stepper-text-contianer {
      border-bottom: ${toRem(1)} solid ${baseVariables.color.neutral20};
      .m-stepper-label {
        width: ${toRem(160)};
        text-wrap: wrap;
      }
    }
  }
  .m-dropdown-header {
    background: ${baseVariables.color['base20']};
    z-index: 1;
    position: sticky;
    top: 0;
    border-bottom: unset;
    padding: ${props =>
      props.$isTabbedSearchForm
        ? `${toRem(0)} ${toRem(0)} ${toRem(12)} ${toRem(0)}`
        : `${toRem(16)} ${toRem(0)} ${toRem(12)} ${toRem(0)}`};
    &:after {
      content: '';
      position: absolute;
      left: 50%;
      top: ${toRem(66)};
      transform: translateX(-50%);
      width: 100%;
    }
  }

  .dropdown-to-modal {
    height: ${props => (props.$isTabbedSearchForm !== true ? `` : '80vh')};
    position: fixed;
    .child-collection {
      padding-bottom: 15%;
    }
    .m-dropdown-content {
      max-height: 83vh;
      &::-webkit-scrollbar {
        width: ${props => (props.$isTabbedSearchForm ? '0' : 'inherit')};
      }
    }
  }

  @media ${baseVariables.mediaQuery.lg} {
    position: relative;
    .tabbed-form-dropdown-container {
      top: unset !important;
    }
    .dropdown-container {
      position: absolute;
      z-index: 1011;
      left: 0;
      top: 0;
      &:before {
        background: none;
      }

      .dropdown-modal-content {
        height: fit-content;
        max-height: fit-content;
        padding: ${toRem(16)};
        width: ${toRem(348)};
      }

      & .m-dropdown-container {
        margin: 0 auto;
        height: fit-content;
        width: ${toRem(348)};
        top: 75%;
        border-radius: ${toRem(8)};
      }
    }
    .m-dropdown-children {
      padding: 0;
    }
  }
  .rooms-guests-dropdown-container {
    left: unset;
    right: 0;
  }
`;
