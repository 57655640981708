// Imports for external libraries go here.
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { FC } from 'react';
import clsx from 'clsx';
import { Heading, size, headingType, Button, Icon } from '@marriott/mi-ui-library';
import { LinkTargetType } from '../../utils';

// Imports for internal (to the monorepo) libraries go here,
// separated by a blank line from external imports.
// The closer the import is to the file the lower it should be in this list.
import { IconFeatureBannerProps } from './IconFeatureBanner.types';
import { StyledIconFeatureBanner } from './IconFeatureBanner.styles';

// Use named rather than default exports.
const IconFeatureBannerComp: FC<{ model: IconFeatureBannerProps }> = props => {
  const {
    id,
    iconPath_1,
    iconPath_2,
    iconPath_3,
    iconPath_4,
    resourceProperties: {
      headerTag,
      desktopDescription,
      mobileDescription,
      header,
      mobileHeader,
      primaryCtaLink,
      primaryLinktext,
      primaryOpenInNewTab,
      primaryAriaLabel,
      secondaryAriaLabel,
      secondaryCtaLink,
      secondaryLinktext,
      secondaryOpenInNewTab,
      iconAltText_1,
      iconAltText_2,
      iconAltText_3,
      iconAltText_4,
      iconAltTextWrap_1,
      iconAltTextWrap_2,
      iconAltTextWrap_3,
      iconAltTextWrap_4,
      contentBlock,
      enableScrollingBehavior,
    },
    trackingProperties,
    appliedCssClassNames,
  } = props.model;
  const hasSecondaryButton =
    secondaryLinktext && secondaryCtaLink && secondaryLinktext.length > 0 && secondaryCtaLink.length > 0;
  const { INTERNAL, EXTERNAL } = LinkTargetType;
  const iconsData = [
    {
      iconPath: iconPath_1,
      iconAltText: iconAltText_1,
      forceWordWrap: iconAltTextWrap_1,
    },
    {
      iconPath: iconPath_2,
      iconAltText: iconAltText_2,
      iconAltTextWrap: iconAltTextWrap_2,
    },
    {
      iconPath: iconPath_3,
      iconAltText: iconAltText_3,
      iconAltTextWrap: iconAltTextWrap_3,
    },
    {
      iconPath: iconPath_4,
      iconAltText: iconAltText_4,
      iconAltTextWrap: iconAltTextWrap_4,
    },
  ];

  const generateIconBlockcontent = (style: string) => {
    return (
      <div className={clsx('icon-block-container', `${style}`)}>
        {iconsData.map(({ iconPath, iconAltText, iconAltTextWrap }) => (
          <div className="icon-block-item" key={iconAltText}>
            <div className="icon-block-item-inner">
              <Icon iconClass={`icon-${iconPath}`} />
              <span className={clsx('t-label-xs icon-label', iconAltTextWrap === 'true' && 'force-wr')}>
                {iconAltText}
              </span>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const customAttributes = enableScrollingBehavior ? { 'data-section-tracking': id } : {};

  return (
    <StyledIconFeatureBanner
      data-component-name="o-homepage-iconfeaturebanner"
      data-testid="iconfeaturebanner"
      className={clsx(appliedCssClassNames && appliedCssClassNames)}
      {...customAttributes}
    >
      <div className="row wrapper-container">
        <div className={clsx('col-12 content-container', contentBlock === 'center' && 'ifb-content-center')}>
          <Heading
            customClass={clsx('ifb-head', mobileHeader && 'd-none d-md-flex')}
            titleText={header}
            variation={headingType.title}
            fontSize={size.medium}
            element={headerTag}
          />
          {mobileHeader && (
            <Heading
              customClass="ifb-head d-md-none"
              titleText={mobileHeader}
              variation={headingType.title}
              fontSize={size.medium}
              element={headerTag}
            />
          )}
          <p className={clsx('t-font-alt-m mt-1 mb-5 mb-md-4 ifb-desc', mobileDescription && 'd-none d-md-flex')}>
            {desktopDescription}
          </p>
          {mobileDescription && <p className="t-font-alt-m mt-1 mb-5 ifb-desc d-md-none">{mobileDescription}</p>}
          {generateIconBlockcontent('d-md-none mb-1 mb-md-0 mt-1 mt-md-0')}
          <div className="btn-container mt-5 mt-md-0">
            <Button
              isLink
              href={primaryCtaLink}
              className={`mb-3 mb-md-0 m-button-primary m-button-m ${
                primaryOpenInNewTab === 'true' ? 'm-button-external' : ''
              }`}
              target={primaryOpenInNewTab === 'true' ? '_blank' : ''}
              trackingProperties={trackingProperties}
              custom_click_track_value={`${header}|${primaryLinktext}|${
                primaryOpenInNewTab === 'true' ? EXTERNAL : INTERNAL
              }`}
              ariaLabel={primaryAriaLabel ?? primaryLinktext}
            >
              {primaryLinktext}
            </Button>
            {hasSecondaryButton && (
              <Button
                isLink
                href={secondaryCtaLink}
                className={`ml-md-3 m-button-secondary m-button-m ${
                  secondaryOpenInNewTab === 'true' ? 'm-button-external' : ''
                }`}
                target={secondaryOpenInNewTab === 'true' ? '_blank' : ''}
                custom_click_track_value={`${header}|${secondaryLinktext}|${
                  secondaryOpenInNewTab === 'true' ? EXTERNAL : INTERNAL
                }`}
                ariaLabel={secondaryAriaLabel ?? secondaryLinktext}
              >
                {secondaryLinktext}
              </Button>
            )}
          </div>
        </div>
        {generateIconBlockcontent('col-6 d-none d-md-flex has-width')}
      </div>
    </StyledIconFeatureBanner>
  );
};

const IconFeatureBannerConfig = {
  emptyLabel: 'Icon Feature Banner',
  isEmpty: () => true,
  resourceType: `${process.env['NEXT_PUBLIC_AEM_SITE']}/components/content/iconfeaturebanner`,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const IconFeatureBanner = (props: any) => (
  <div data-testid="icon-featured-banner">
    {props.cqPath?.includes('experiencefragment') ? (
      <div className={props.appliedCssClassNames}>
        <IconFeatureBannerComp {...props} />
      </div>
    ) : (
      <EditableComponent config={IconFeatureBannerConfig} {...props}>
        <IconFeatureBannerComp {...props} />
      </EditableComponent>
    )}
  </div>
);
