/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { toRem, baseVariables as theme, baseVariables } from '@marriott/mi-ui-library';

export const StyledDatePickerExactDiv = styled.div``;

export const StyledDatePickerDiv = styled.div<{ $isTabbedSearchForm?: boolean }>`
  ${rtl`

.DayPicker-Day:focus-visible .DayPicker-Day-Cell {
    border: ${toRem(2)} solid ${theme.color.base10};
    border-radius: 50%;
 }

 .text-overflow-ellipsis{
    text-overflow: ellipsis;
 }

 .DayPicker-Day:focus-visible {
  border: none;
  outline: none;
 }

  .inactive-tab {
    opacity: 1 !important;
  }
  .clear-input-icon {
    font-size: ${toRem(22)};
    vertical-align: bottom
  }
  .m-stepper-wrapper{
    justify-content:${(props: any) => (props.$isTabbedSearchForm === true ? 'flex-start' : 'space-between')};
   
  }
  .m-stepper button.btn-disable{
    pointer-events:all;
    cursor: not-allowed;
  }
  .m-badge-inline-neutral {
    padding-top:${toRem(4)};
    padding-bottom:${toRem(4)};
  }

  .weekend-selector-horizontal-line{
    position:relative;
    top:${toRem(16)};
    border-bottom: ${toRem(1)} solid ${theme?.color?.neutral20};
  }
  .weekend-selector-horizontal-line-flexible{
    position:relative;
    top:${toRem(24)};
    border-bottom: ${toRem(1)} solid ${theme?.color?.neutral20};
  }
  .m-switcher-tab-list-item a span {
    font-size: ${toRem(13)};
    color: ${theme?.color?.neutral40};
  }
  .weekend-selector{
    border-radius: ${toRem(52)};
    margin-top: ${toRem(24)} !important;
    &.selected-state, &.disabled {
      margin: auto;
      line-height: 1.125rem;
      font-weight: 500;
      color: ${theme?.color?.neutral40};
      background: ${theme?.color?.neutral10};
      border-color: ${theme?.color?.neutral10};
    }
    .icon-calendar {
      font-size: ${toRem(14)};
    }
    .icon-calendar:before {
      width:${toRem(16)};
      height:${toRem(16)};
    }
  }
  .date-text-font{
    font-weight: 700 !important;
  }
  .date-text-font-m{
    font-weight: 700 !important;
    font-size : ${toRem(14)} !important;
  }
  .date-text-font-l{
    font-weight: 700 !important;
    font-size : ${toRem(10)} !important;
  }
  .date-text-font-xl{
    font-weight: 700 !important;
    font-size : ${toRem(8)} !important;
  }

  @media ${theme.mediaQuery.lg} {
    .m-input-text-field-no-border label {
      margin-bottom: ${toRem(4)};
    }
    .shop-dropdown-children-el {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
    }
  }
  .selected-month {
    background-color:${theme.color.neutral20};
    color:${theme.color.neutral00} ;
    border: ${toRem(2)} solid ${theme.color.neutral40};
  }
  .selected-month: hover {
    background-color:${theme.color.neutral20};
    color:${theme.color.neutral00} ;
    box-shadow: none;
    border: ${toRem(2)} solid ${theme.color.neutral40};
  }

  .DayPicker-wrapper{
    padding:0;
  }
  .DayPicker-wrapper {
    padding-bottom: ${toRem(72)}; // this is set to 72px as the height of calendar footer is 72px
    
    @media ${baseVariables.mediaQuery.lg}{
      padding-bottom: 0;
    }
  }
  .date-cursor input{
    cursor:pointer;
  }
  .date-cursor label{
    cursor:pointer;
  }
  &.date-picker-wrapper{
    .date-input-field-wrapper  input{
      cursor:pointer;
    }
    @media ${theme.mediaQuery.lg}{
    .date-input-field-wrapper {
      padding-left: ${toRem(40)};
    }
    .shop-datepicker-dropdown{
      margin-top: ${toRem(15)};
    }
    .shop-datepicker-dropdown-styles{
      min-height:unset;
      height:auto !important;
      display: block !important;
    }

    .align-right {
      right: 0 !important;
    }
  }
    &.date-picker-mobile-wrapper{
      .modal-header-input{
        position: fixed;
        z-index: 1012;
        background: ${baseVariables.color['base20']};
        padding-bottom: ${toRem(24)} !important;
        width: calc(100% - ${toRem(48)});
        input{
          border-color:${theme.color.base10} !important;
        }
        .modal-header-input{
          @media ${theme.mediaQuery.lg}{
            padding-bottom: ${toRem(8)};
          }
        }
      }
      .m-dropdown-header{
        padding-left:0;
        padding-right:0;
        position: fixed !important;
        top: initial !important;
        width: calc(100% - ${toRem(48)});
        z-index: 1012;
        background: ${baseVariables.color['base20']};
        &:after{
          width:100%;
        }
      }
      .modal-header-sticky{
        position: relative;
        margin-top: ${toRem(57)};
        @media ${theme.mediaQuery.lg}{
          margin-top: 0;
        }
      }
    }
    .shop-datepicker-modal{
      width:100%;
      .shop-date-picker-drop-down-cn{
        margin-top: ${toRem(15)};
        @media ${theme.mediaQuery.lg} {
          .DayPicker-WeekdaysRow {
            gap: ${toRem(4.8)};
          }
        } 
      }
      .dropdown-to-modal{
        height:${(props: any) => (props.$isTabbedSearchForm !== true ? `${toRem(600)}` : '80vh')};
        padding:0;
        @media ${theme.mediaQuery.md}{
          position:fixed;
        }
        
        .m-dropdown-content{
          padding: 0 ${toRem(24)} ${toRem(24)} ${toRem(24)} !important;
          margin-top: ${toRem(24)};
          scrollbar-width: thin;
          scrollbar-color: transparent transparent;
          &::-webkit-scrollbar {
            width: 0;
          }
          &::-webkit-scrollbar-track {
            background: transparent;
          }
          &::-webkit-scrollbar-thumb {
            background-color: transparent;
          }
        }
      }

     
      .DayPicker-Month {
        width:calc(100vw - ${toRem(48)}) !important;
      }
      @media ${theme.mediaQuery.lg}{
        .DayPicker-Months {
          gap:${toRem(32)}
        }
        .DayPicker-NavButton--next{
          right:0 !important;
        }
        .DayPicker-NavButton--prev{
          left:0 !important;
        }
        .DayPicker-Month {
          width:${toRem(316)} !important;
          margin-left:0 !important;
          margin-right:0 !important;
          margin-top: ${toRem(16)} !important;
        }
        .DayPicker-Weekdays {
          display:flex;
        }
        .DayPicker-WeekdaysRow {
          display: flex;
          width:100%;
          justify-content:space-around;
        }
        .DayPicker-Body {
          display:flex;
          flex-direction: column;
          align-items: center;
        }
        
        .DayPicker-Week {
          display: flex;
          width:100%;
          justify-content:space-around;
          align-items:stretch;
        }
        .DayPicker-Day {
          display: flex;
          height: ${toRem(40)};
          justify-content: center;
          align-items: center;
          width: 100%;
        }
        .DayPicker-Body{
          .DayPicker-Week{
            .DayPicker-Day{
              &.DayPicker-Day--hoverRange:not(.DayPicker-Day--selected){
                .DayPicker-Day-Cell{
                  width:100% !important;
                }
              }
              &.DayPicker-Day--hoverRange{
                &.DayPicker-Day--select {
                  background-color:${baseVariables.color['neutral10']} !important;
                  width:100% !important;
                }
              } 
            }
          }
        }
        .DayPicker-Day-Cell:focus-visible{
          border-radius:50%;
        }
        .DayPicker-Weekdays {
          abbr {
            cursor:auto;
          }
        }
        position:fixed;
        width:${toRem(712)};
        height:${toRem(466)} !important;
        left:auto;
        z-index:1011;
        right:8px;
        &.is-search-form-sticky {
          right:0;
        }
        .m-dropdown-content{
          padding:${toRem(24)} ${toRem(24)} !important;
          max-height:100%;
          min-height:unset !important;
        }
      }
      .icon-calendar{
        margin-right: ${toRem(4)};
      }
      .tab-wrapper{
        box-shadow: none;
        padding-bottom:0;       
         padding-top: ${toRem(63)}; 
         ul{
          position: fixed;
          width: calc(100% - ${toRem(48)});
          background: ${baseVariables.color['base20']};
          z-index: 1012;
          padding-top: ${toRem(13)};
          margin-top: ${toRem(47)};

          li{
            background: ${baseVariables.color['neutral10']};
            padding: ${toRem(3)} 0;
          }
          .m-switcher-tab-list-item.active{
            background: ${baseVariables.color['base20']} !important;
            a span {
              font-size: ${toRem(13)};
              color: ${theme?.color?.base10};
            }
          }     
      }

        padding-top:${toRem(16)};
        @media ${theme.mediaQuery.lg}{
          padding-top:0;
         ul{
          width: ${toRem(327)};
          align-items:center;
          justify-content:center;
          margin: 0 auto ${toRem(16)};
          position: relative;
          background: ${baseVariables.color['neutral10']};
          padding-top: ${toRem(4)};
         }
        }
      }
        box-shadow:none !important;
        .datepicker-calendar-wrapper-multi-tabs{
          border-top:${theme.border.borderWidth01} ${theme.border.borderStyleDefault} ${theme.color.neutral20};
        }
        .datepicker-calendar-wrapper{
          margin-top: ${toRem(82)};
          @media ${theme.mediaQuery.lg}{
            margin-top: ${toRem(0)};
          }
        }
        .datepicker-calendar-wrapper-mobile{
          margin-top: ${toRem(50)};
        }
        .datepicker-tab-wrapper{
          align-items:center;
          justify-content:center;
          margin: 0 auto ${toRem(16)};
          width:auto;
          @media ${theme.mediaQuery.lg}{
          width:${toRem(327)};
          }

        }
        .datepicker-tab-wrapper-exact{
          padding-top:${toRem(4)} !important;
          margin-top: ${toRem(-24)} !important;
        }
      }
      .shop-datepicker-modal-tabbedform{
        @media ${theme.mediaQuery.lg}{
          left:unset;
          margin-top:${toRem(4)};
          .dropdown-to-modal{
            padding:${toRem(24)};
        }
      }
    }
  }
`}
`;

export const StyledFlexiCalendarBoxDiv = styled.div`
  ${rtl`
  &.search__flexible_calendar{
    border-top:${theme.border.borderWidth01} ${theme.border.borderStyleDefault} ${theme.color.neutral20};
    .stepper-wrapper{
      @media ${theme.mediaQuery.lg}{
      max-width:${toRem(286)};
      }
      margin-top: ${toRem(82)};
    }
    .stepper-wrapper{
      @media ${theme.mediaQuery.lg}{
       margin-top: 0;
      }
    }
    .m-stepper-label{
      min-width: ${toRem(118)};
    }
    
    .m-stepper{
      padding-left: ${toRem(24)};
    }
    .default-month-state {
      color: ${theme.color.neutral40};
      border-color: ${theme.color.neutral30};
      font-size: ${toRem(14)};
    }
    .default-month-state: hover{
      background-color:${theme.color.neutral20} ;
      color:${theme.color.base10} ;
      box-shadow: none;
    }
    .months-pill-container{
      padding-bottom: ${toRem(6)} !important;
      .month-pill:focus:not(:focus-visible) {
        outline: 0;
        box-shadow: none;
      }     
      .month-pill{
        min-width:${toRem(104)};
        font-weight:500;
        @media only ${theme.mediaQuery.lg}{
          min-width:${toRem(100)};
        }
        &:nth-child(3){
          @media only ${theme.mediaQuery.md}{
            margin-right:${toRem(8)} !important;
          }
        }
      }
    }
  }
`}
`;

export const StyledFooterDatePickerDiv = styled.div`
  ${rtl`
      position: fixed;
      z-index: 1011;
      left: 0;
      right: 0;
      bottom: 0;
      background: ${theme.color['base20']};
      @media ${theme.mediaQuery.lg}{
        position:static;
      }
      .reset-action-button{
        background:none;
      }
`}
`;
