import { tags, renditions } from '@marriott/mi-ui-library';

export interface MerchandisingSmallBannerProps {
  openInNewTab: boolean;
  destinationUrl1: string;
  callToActionText1: string;
  ctaType?: string;
  headerLong: string;
  descriptionLong?: string;
  descriptionLongMobile?: string;
  headerTag?: tags;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  trackingProperties?: any;
  image?: renditions;
  styleclass?: string;
  componentId?: string;
  openInNewWindowAriaLabel?: string;
  customDescriptionClass?: string;
  customContainerClass?: string;
  customImageClass?: string;
  mboxParameter?: string;
  displayEvergreenContent?: string;
  isTargetContentError?: string;
  isTargetContentLoaded?: boolean;
}

export interface MerchandisingSmallBannerMBoxProps {
  targetData?: {
    mathLabel1?: string;
    mathLabel2?: string;
    mathValue?: string;
    mathLabel3?: string;
    totalAfterCreditAmount?: string;
    mathTitle?: string;
    legalLong?: string;
    mathType?: string;
  };
}

export enum ButtonStyle {
  PRIMARY_BUTTON = 'm-button-primary',
  PRIMARY_BUTTON_EXTERNAL = 'm-button-primary m-button-external',
  SECONDARY_BUTTON = 'm-button-secondary',
  SECONDARY_BUTTON_EXTERNAL = 'm-button-secondary m-button-external',
  TERTIARY_BUTTON = 'm-link-tertiary-button',
  TERTIARY_BUTTON_EXTERNAL = 'm-link-tertiary-button-external',
}

export interface TrackingPropsInterface {
  trackingContentPosition?: string;
  trackingOfferType?: string;
  atCCeVar48?: string;
  trackingDescription?: string;
  trackingTag?: string;
  clickTrack?: boolean;
  impressionTrack?: boolean;
  impressionCount?: boolean;
  impressionEventType?: string;
  merchandisingCategory?: string;
  additionalTrackingVariables?: string;
  location?: string;
  payloadType?: string;
  compName?: string;
  enableScrollingBehavior?: boolean;
  isAuth?: boolean;
  clickTrackValSeparator?: string;
  cell?: string;
  spid?: string;
  rpcCode?: string;
  offerCode?: string;
  isDCAEnabled?: boolean;
  type?: string;
}

export interface SmallBannerProps {
  openInNewTab: boolean;
  destinationUrl1: string;
  callToActionText1: string;
  ctaType?: string;
  headerLong: string;
  descriptionLong?: string;
  descriptionLongMobile?: string;
  headerTag?: tags;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  trackingProperties?: any;
  image?: renditions;
  styleclass?: string;
  componentId?: string;
  openInNewWindowAriaLabel?: string;
  customDescriptionClass?: string;
  customContainerClass?: string;
  customImageClass?: string;
}
