export type MemberPanelCardLink = {
  cardLinkLabel: string;
  cardLinkPath: string;
  cardLinkId?: string;
};

export type MemberPanelMemberItems = {
  memberLabel: string;
  memberUrl: string;
  memberType: string;
};

export type SignedMemberPanelProps = {
  pointsLabel: string;
  buyPointsLinkLabel: string;
  buyPointsLinkPath: string;
  memberPanelCardLinks?: MemberPanelCardLink[];
  logoutLinkLabel: string;
  logoutLinkPath: string;
  memberItems?: MemberPanelMemberItems[];
};

export interface MemberPanelProps extends SignedMemberPanelProps {
  joinNowLabel: string;
  joinNowSubHeading: string;
  becomeMemberLabel: string;
  becomeMemberUrl: string;
}

export const MEMBERPANEL_CONSTANTS = {
  MY_TRIPS: 'myTrips',
  BENEFITS: 'benefits',
};
