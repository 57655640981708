/* eslint-disable @typescript-eslint/no-explicit-any */
// Imports for external libraries go here.
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import moment from 'moment';
/* eslint-disable no-inner-declarations */
// Imports for internal (to the monorepo) libraries go here,
// separated by a blank line from external imports.
// The closer the import is to the file the lower it should be in this list.
import { SearchFormProps } from './SearchForm.types';
import { StyledSearchForm, StyledSearchFormWrapper } from './SearchForm.styles';
import { useSearchFormStore } from '../../modules/store/searchFormStore';
import { getDateObj, getTotalNumberOfDays } from '@marriott/mi-ui-library';
import { SearchFormView } from './SearchForm.controller';
import clsx from 'clsx';
import { StaticDataContextProvider, useStaticDataContext } from '../../modules/context';
import { DATES, HOME_PAGE_HEADER_CONST_HEIGHT } from '../../modules/constants';
import { DESTINATION, RESORTS_HIDDEN_FIELDS, ROOMS_AND_GUESTS } from '../../modules/store/store.constants';
/* eslint-disable @nx/enforce-module-boundaries */
import { getSessionData } from '@marriott/mi-headless-utils';
import { usePersistentGlobalStore, useScrollStore } from '@marriott/mi-store-utils';
import { logger } from '@marriott/mi-headless-utils';
/* eslint-enable @nx/enforce-module-boundaries */
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { toRem } from '@marriott/mi-ui-library';
import { constructSearchformLabels } from '../../modules/utils/searchFormHelper';
import { getDateFormattedFromURL } from '../../utils/src/DateUtils';
import { getRoomRange } from '../../utils/src/utils';

if (global && !global.loggerInstance) {
  global.loggerInstance = logger({ requestID: '', sessionID: '' }) as CallableFunction;
}

/**
 *model ={<An AEM model with all author value>}
 * @param props
 * @returns
 */
// Use named rather than default exports.
export const SearchForm: FC<SearchFormProps> = props => {
  const searchFormOnHomepage = props?.model?.['enableOverlayTop'];
  const { model, currentLocale } = props;
  const TabbedForm =
    typeof model?.['appliedCssClassNames'] === 'string' && model?.['appliedCssClassNames'].toLowerCase() === 'phoenix'
      ? true
      : false;
  constructSearchformLabels(model);
  const callGetSessionData = props?.model?.numberOfSearchForms || props?.model?.enableOverlayTop ? true : false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updateGlobalData = usePersistentGlobalStore((state: any) => state.updateGlobalData);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const globalData = usePersistentGlobalStore((state: any) => state.globalData);
  /**
   * shop store to access the data
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const shopStoreInitialization = useSearchFormStore((state: any) => state.shopStoreInitialization);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setSearchFormState = useSearchFormStore((state: any) => state.setSearchFormState);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const isTabbedSearchContainer = useSearchFormStore((state: any) => state?.tabbedSearchFormContainer);
  // variable to determine whether tabbed view is in Base line search form or from the TabbedSearchForm
  const isBaseLineSearchTabbedView = TabbedForm && !isTabbedSearchContainer?.tabbedSearchFormContainer;

  // const [isSticky, setIsSticky] = useState(false);

  /**
   * import search from component with modification
   * and state implemention
   */

  useEffect(() => {
    setSearchFormState(
      [RESORTS_HIDDEN_FIELDS],
      {
        [RESORTS_HIDDEN_FIELDS]: {
          hotelType: model?.hotelType,
          activities: model?.resortActivities,
          amenities: model?.amenities,
        },
      },
      true
    );
  }, [model?.hotelType, model?.resortActivities, model?.amenities, setSearchFormState]);

  useEffect(() => {
    async function getSession() {
      // Extract the sessionID cookie value from browser cookies
      const sessionID = document?.cookie?.split('sessionID=')[1]?.split(';')[0];
      // Make the client side GET session call
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const response: any = await getSessionData(sessionID, false, false);
      // Initialize the zustand store with the new sessionData
      shopStoreInitialization(response?.sessionData, model);
      // Update the sessionData value in global-store
      updateGlobalData('sessionData', response?.sessionData);
    }
    // make the client side session call from SearchForm component
    if (callGetSessionData) {
      getSession();
    } else {
      // Initialize the zustand store with the sessionData available in props
      !model.isTabbedSearchForm && shopStoreInitialization(globalData?.sessionData, model);
    }
  }, []);

  const scrollPosition = useRef<number>(0);
  const searchFormContainerElRef = useRef<HTMLDivElement>(null);
  const searchFormContainerRef = useRef<HTMLDivElement>(null);
  let lastScrollPosition = 0;
  let searchFormEl = null;
  let searchFormEl2 = null;
  const { isTabbedSearchForm } = useStaticDataContext(); // please do not remove this , breaks the form , its been used

  let scrollThreshold = 0;
  const [isHtlHeader, setIsHtlHeader] = useState(false);
  const [searchformScrollThreshold, setSearchformScrollThreshold] = useState(0);
  const [homePageHeaderHeight, setHomePageHeaderHeight] = useState(0);
  let homePageBannerCompHeight = 0;
  const searchFormPadding = 24;
  const [topPosition, setTopPosition] = useState(0);

  const [searchFormPositionWrapperRef, setSearchFormPositionWrapperRef] = useState<HTMLDivElement>();

  const setRegisteredComponent = useScrollStore(state => state.setRegisteredComponent);

  const onRefChange = useCallback((ref: HTMLDivElement) => {
    if (!ref) {
      return;
    }
    setRegisteredComponent({
      componentId: model.componentId,
      height: ref.clientHeight,
      isStickyOnScrollUp: !!model?.scrollSetting?.isStickyOnScrollUp,
      isStickyOnScrollDown: !!model?.scrollSetting?.isStickyOnScrollDown,
    });
    setSearchFormPositionWrapperRef(ref);
  }, []);

  useEffect(() => {
    const htlHeaderComp = document.querySelector('.m-header');
    const homePageBannerComp = document.querySelector('.ab');
    if (!homePageBannerComp) return;
    const resizeObserver = new ResizeObserver(() => {
      const htlHeaderHeight = htlHeaderComp?.getBoundingClientRect().height ?? HOME_PAGE_HEADER_CONST_HEIGHT;
      homePageBannerCompHeight = homePageBannerComp ? homePageBannerComp?.clientHeight : 0;
      setTopPosition(htlHeaderHeight + homePageBannerCompHeight);
    });
    resizeObserver.observe(homePageBannerComp);
    return () => resizeObserver.disconnect(); // clean up
  }, []);

  // const { offset: stickyOffset, top: stickyTop } = useStickyState(model.componentId);
  const stickyOffset = 0;
  const stickyTop = 0;
  useEffect(() => {
    /**
     * to set the homepage Header height
     */
    //since this element is from AEM script, need to handle it with query selector.
    const htlHeaderComp = document.querySelector('.m-header');
    const homePageBannerComp = document.querySelector('.ab');
    const htlHeaderHeight = htlHeaderComp?.getBoundingClientRect().height ?? HOME_PAGE_HEADER_CONST_HEIGHT;
    homePageBannerCompHeight = homePageBannerComp ? homePageBannerComp?.clientHeight : 0;

    const inititalTopPosition = !htlHeaderComp ? stickyOffset : htlHeaderHeight + homePageBannerCompHeight;
    setTopPosition(inititalTopPosition);

    // set initial top position for search form, references htl fragment header if present or we use scroll store
    if (htlHeaderComp) {
      setIsHtlHeader(true);
      setSearchformScrollThreshold(htlHeaderHeight + homePageBannerCompHeight + searchFormPadding);
      setHomePageHeaderHeight(htlHeaderHeight);
    } else {
      setSearchformScrollThreshold(stickyOffset + searchFormPadding);
      stickyTop && setHomePageHeaderHeight(stickyTop);
    }
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      window.removeEventListener('scroll', handleScroll);
      const offsetVal = isBaseLineSearchTabbedView ? 144 : 24;
      let searchFormTopPositionValue = searchFormPositionWrapperRef?.offsetTop;
      const searchFormTopPositionValuePostScroll = searchFormPositionWrapperRef?.getBoundingClientRect()?.top;
      if (searchFormTopPositionValuePostScroll && searchFormTopPositionValuePostScroll < offsetVal) {
        searchFormTopPositionValue = 0;
      }
      if (searchFormTopPositionValue) {
        setSearchFormState(['searchFormTopPosition'], {
          searchFormTopPosition: {
            value: searchFormTopPositionValue - offsetVal,
          },
        });
      } else {
        setSearchFormState(['searchFormTopPosition'], {
          searchFormTopPosition: {
            value: window.scrollY,
          },
        });
      }
    };
    if (!model.isTabbedSearchForm || isBaseLineSearchTabbedView) {
      const offsetVal = isBaseLineSearchTabbedView ? 144 : 24;
      let searchFormTopPositionValue = searchFormPositionWrapperRef?.offsetTop;
      const searchFormTopPositionValuePostScroll = searchFormPositionWrapperRef?.getBoundingClientRect()?.top;

      if (searchFormTopPositionValuePostScroll && searchFormTopPositionValuePostScroll < offsetVal) {
        searchFormTopPositionValue = 0;
      }
      if (searchFormTopPositionValue) {
        setSearchFormState(['searchFormTopPosition'], {
          searchFormTopPosition: {
            value: searchFormTopPositionValue - offsetVal,
          },
        });
      } else {
        setSearchFormState(['searchFormTopPosition'], {
          searchFormTopPosition: {
            value: window.scrollY,
          },
        });
      }
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
    return () => {};
  }, [searchformScrollThreshold]);
  /**
   * checks the scroll position wrt threshold(header) so as to add the necessary scroll CSS
   */
  const handleScrollPosition = (updatedCurrentScrollPosition: number, scrollThreshold: number) => {
    searchFormEl = searchFormContainerElRef.current;
    searchFormEl2 = searchFormContainerRef.current;
    if (updatedCurrentScrollPosition > scrollThreshold && scrollPosition.current < scrollThreshold) {
      scrollPosition.current = updatedCurrentScrollPosition;

      /**
       *makes the SearchForm full width if user scrolls and vice versa if back to original state
       */

      if (searchFormEl) {
        searchFormEl?.classList.add('color-scheme1');
      }
      if (searchFormEl2) {
        searchFormEl2?.classList.remove('search-form-wrapper');
      }
    } else if (updatedCurrentScrollPosition <= scrollThreshold && scrollPosition.current > scrollThreshold) {
      scrollPosition.current = updatedCurrentScrollPosition;
      if (searchFormEl) {
        searchFormEl?.classList.remove('color-scheme1');
      }
      if (searchFormEl2) {
        searchFormEl2?.classList.add('search-form-wrapper');
      }
    }
  };

  /**
   * function to handle the scroll state of the searchForm when user scrolled(fsd-80422)
   */
  const handleScroll = () => {
    const currentScrollPosition = window.scrollY;
    scrollThreshold = searchformScrollThreshold;
    searchFormEl = searchFormContainerElRef.current;
    searchFormEl2 = searchFormContainerRef.current;

    //since this element is from AEM header or alert banner, need to handle it with query selector.
    const alertBanner = document.querySelector('.ab__desc');
    const alertBannerComp = document.querySelector('.ab');
    const alertBannerHeight = alertBannerComp?.clientHeight ?? 0;
    const htlHeaderComp = document.querySelector('.m-header');
    const htlHeaderTop = htlHeaderComp?.getBoundingClientRect().top;

    let updatedCurrentScrollPosition = currentScrollPosition;
    if (currentScrollPosition > lastScrollPosition && currentScrollPosition >= homePageHeaderHeight && htlHeaderTop) {
      updatedCurrentScrollPosition += Math.abs(htlHeaderTop);
      scrollThreshold += Math.abs(htlHeaderTop);
      if (updatedCurrentScrollPosition < scrollThreshold) {
        const calculatedTop = homePageHeaderHeight + alertBannerHeight;
        setTopPosition(calculatedTop);
      }
    }
    /**
     * Close the alert Banner on the scroll of the page
     */
    if (updatedCurrentScrollPosition > alertBannerHeight) {
      alertBanner && alertBanner?.classList.add('hide-visibility');
      alertBannerComp && alertBannerComp?.classList.add('hide-alert');
    }
    /**
     * checks the scroll previous position and current position
     */
    /**
     * remove the sticky css class if searchForm back to top
     */
    if (updatedCurrentScrollPosition === 0) {
      scrollPosition.current = 0;
      searchFormEl?.classList.remove('sticky-search-top');
      searchFormEl?.classList.remove('sticky-search-container');
      // setIsSticky(false);
      searchFormEl?.classList.remove('search-container-top');
      const topPosition = !isHtlHeader ? stickyOffset : homePageHeaderHeight + alertBannerHeight;

      //when updatedCurrentScrollPosition === 0 , searchform should have 'search-form-wrapper' class applied.
      if (searchFormEl) {
        searchFormEl?.classList.remove('color-scheme1');
      }
      if (searchFormEl2) {
        searchFormEl2?.classList.add('search-form-wrapper');
      }
      setTopPosition(topPosition);
    } else if (
      updatedCurrentScrollPosition !== 0 &&
      updatedCurrentScrollPosition <= scrollThreshold - homePageHeaderHeight &&
      updatedCurrentScrollPosition < lastScrollPosition
    ) {
      /**
       * For scrolling up add the respective CSS
       */
      // always force top position to 0 when scrolling up, class changes don't always apply in the next frame
      setTopPosition(htlHeaderComp ? updatedCurrentScrollPosition + homePageHeaderHeight : stickyOffset);
      handleScrollPosition(updatedCurrentScrollPosition, scrollThreshold);
      searchFormEl?.classList.remove('sticky-search-top');
      searchFormEl?.classList.remove('sticky-search-container');
      // setIsSticky(false);
      searchFormEl?.classList.remove('search-container-top');
    } else if (updatedCurrentScrollPosition > scrollThreshold && updatedCurrentScrollPosition > lastScrollPosition) {
      /**
       * For scrolling down add the respective CSS
       */
      setTopPosition(htlHeaderComp ? homePageHeaderHeight : stickyTop);
      handleScrollPosition(updatedCurrentScrollPosition, scrollThreshold);
      searchFormEl?.classList.add('sticky-search-container');
      searchFormEl?.classList.remove('sticky-search-top');
      // setIsSticky(true);
      searchFormEl?.classList.remove('search-container-top');
    } else if (updatedCurrentScrollPosition < lastScrollPosition) {
      /**
       * For scrolling top add the respective CSS
       */
      handleScrollPosition(updatedCurrentScrollPosition, scrollThreshold);
      searchFormEl?.classList.add('sticky-search-top');
      setTopPosition(htlHeaderComp ? homePageHeaderHeight : stickyTop);
      searchFormEl?.classList.remove('sticky-search-container');
      // setIsSticky(true);
    }
    lastScrollPosition = currentScrollPosition;
  };

  useEffect(() => {
    /**
     * adding the scroll event if handleScroll triggers
     * removes scroll if were back to original state
     */
    if (!TabbedForm) {
      window.addEventListener('scroll', handleScroll, { passive: true });
    }
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [TabbedForm, searchformScrollThreshold]);
  const [formLoaded, setFormLoaded] = useState(false);
  useEffect(() => {
    setFormLoaded(true);
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const isAvailabilityScenario = props?.hideDestination && props?.hideBrandsField;
    // Retrieve the URL parameters from the window location search string
    const fromDateParam = urlParams?.get('fromDate') || urlParams?.get('fromDates');
    const fromDates = fromDateParam ? getDateFormattedFromURL(fromDateParam) : null;
    const toDateParam = urlParams?.get('toDate') || urlParams?.get('toDates');
    const toDates = toDateParam ? getDateFormattedFromURL(toDateParam) : null;
    const searchCriteria = globalData?.sessionData?.cacheData?.data?.AriesSearch?.searchCriteria;
    // Attempt to retrieve the property code from either The global session data object if available or URL parameters if the global data is not available.
    const propertyCode = urlParams?.get('propertyCode') || searchCriteria?.propertyId;
    const fromDate = fromDates;
    const toDate = toDates;
    const numofRooms = urlParams?.get('numberOfRooms') || searchCriteria?.availabilityRequestVO?.numRooms;
    const numofAdults = urlParams?.get('numberOfGuests') || searchCriteria?.availabilityRequestVO?.numAdultsPerRoom;
    const numofChildren = urlParams?.get('childrenCount') || searchCriteria?.availabilityRequestVO?.numChildrenPerRoom;
    // Check if both 'hideDestination' and 'hideBrandsField' props are true, and if a property code exists.
    if (isAvailabilityScenario && propertyCode) {
      setSearchFormState(
        [DESTINATION],
        {
          [DESTINATION]: {
            displayText: propertyCode,
            destinationAddressPlaceId: propertyCode,
          },
        },
        true
      );
      // Update the search form state to include additional details for the destination field.
      setSearchFormState(['destinationFieldDetails'], {
        destinationFieldDetails: {
          recentlyViewedPopertyCode: propertyCode,
          isRecentSearchSelected: true,
        },
      });
    }
    if (isAvailabilityScenario && fromDate && toDate) {
      const lengthOfStay = searchCriteria?.availabilityRequestVO?.lengthOfStay;
      setSearchFormState([DATES], {
        [DATES]: {
          fromDate: getDateObj(fromDate),
          toDate: getDateObj(toDate),
          flexible: searchCriteria?.availabilityRequestVO?.flexibleDate,
          numberOfNights:
            fromDate && toDate
              ? Number(getTotalNumberOfDays(moment(fromDate), moment(toDate))?.toFixed(0) || 1)
              : lengthOfStay,
          lengthOfStay: fromDate && toDate ? getTotalNumberOfDays(moment(fromDate), moment(toDate)) : lengthOfStay,
        },
      });
    }
    if (isAvailabilityScenario && numofRooms) {
      setSearchFormState([ROOMS_AND_GUESTS], {
        [ROOMS_AND_GUESTS]: {
          numRooms: getRoomRange(numofRooms),
          numAdultsPerRoom: parseInt(numofAdults, 10),
          numChildrenPerRoom: parseInt(numofChildren, 10),
        },
      });
    } else return;
  }, [globalData?.sessionData]);

  const getSearchformComponent = (TabbedForm: boolean) => {
    if (TabbedForm) {
      return (
        <div ref={isBaseLineSearchTabbedView ? onRefChange : undefined}>
          <StyledSearchForm
            data-component-name="o-shop-searchform"
            data-testid="searchform"
            ref={searchFormContainerElRef}
            className={clsx(
              'd-flex',
              model.isTabbedSearchForm !== true && 'p-3 pt-5',
              'document_search_form_container_el_ref'
            )}
          >
            <StaticDataContextProvider value={{ ...model, currentLocale }}>
              <div
                ref={searchFormContainerRef}
                className={clsx(
                  'container px-0',
                  model.isTabbedSearchForm ? '' : 'search-form-wrapper',
                  'document_search_form_container',
                  'color-scheme1'
                )}
              >
                {/* {!model.isTabbedSearchForm && !formLoaded ? (
                  <></>
                ) : (isTablet && isTabbedSearchForm) || isDesktop ? (
                  <SearchFormDesktopView {...props} isSearchFormSticky={isSticky} sessionData={globalData}/>
                ) : (
                  <SearchFormMobileView {...props} />
                )} */}
                {!formLoaded ? (
                  <></>
                ) : (
                  // <SearchFormView {...props} isSearchFormSticky={isSticky} sessionData={globalData}></SearchFormView>
                  <SearchFormView {...props} sessionData={globalData}></SearchFormView>
                )}
              </div>
            </StaticDataContextProvider>
          </StyledSearchForm>
        </div>
      );
    } else {
      return (
        <StyledSearchFormWrapper
          data-component-name="o-shop-searchform"
          data-testid="searchform"
          ref={onRefChange}
          className={clsx(props?.model?.['appliedCssClassNames'] ? '' : 'm-container-fullbleed', {
            'search-form-position': searchFormOnHomepage,
          })}
          topOffset={toRem(topPosition)}
        >
          <StyledSearchForm
            ref={searchFormContainerElRef}
            className={clsx(
              'd-flex',
              model.isTabbedSearchForm !== true && 'p-3 pt-4',
              'document_search_form_container_el_ref',
              'pt-0 pb-0'
            )}
            style={{ top: toRem(topPosition) }}
            isHtlHeader={isHtlHeader}
          >
            <StaticDataContextProvider value={{ ...model, currentLocale }}>
              <div
                ref={searchFormContainerRef}
                className={clsx(
                  'container px-0',
                  model.isTabbedSearchForm ? '' : 'search-form-wrapper',
                  'document_search_form_container',
                  'color-scheme1'
                )}
              >
                {/* <div className="d-none d-lg-block">
                  <SearchFormDesktopView {...props} isSearchFormSticky={isSticky} />
                </div>
                <div className="d-block d-lg-none">
                  <SearchFormMobileView {...props} />
                </div> */}
                {/* <SearchFormView {...props} isSearchFormSticky={isSticky} sessionData={globalData}></SearchFormView> */}
                <SearchFormView {...props} sessionData={globalData}></SearchFormView>
              </div>
            </StaticDataContextProvider>
          </StyledSearchForm>
        </StyledSearchFormWrapper>
      );
    }
  };

  /**
   * TODO: All store calculation will be done as part of this file
   * We will pass proccess data to useSEarchForm in the form of props
   */
  return <>{getSearchformComponent(TabbedForm)}</>;
};

//Config for aem editable components
export const SearchFormConfig = {
  emptyLabel: 'SearchForm',
  isEmpty: false,
  resourceType: `mi-aem-shop-spa/components/content/homepagesearch/v1/homepagesearch`,
};
//Component wrapped with editable component for aem authoring
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const SearchFormEditable = (props: any) =>
  props.cqPath.includes('experiencefragment') ? (
    <SearchForm {...props} />
  ) : (
    <EditableComponent config={SearchFormConfig} {...props}>
      <SearchForm {...props} />
    </EditableComponent>
  );
