/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment';
import { FC, useState, useEffect } from 'react';
import { StyledFlexiCalendarBoxDiv } from './Datepicker.styles';
import { DatePickerFlexibleProps } from './Datepicker.types';
import { Button, StepperTextBlock, getCurrentDateObject } from '@marriott/mi-ui-library';
import clsx from 'clsx';
import { useStaticDataContext } from '../../modules/context';
import { useSearchFormStore } from '../../modules/store/searchFormStore';
import { MAX_NUMBER_OF_NIGHTS_ALLOWED } from '../../modules/constants/Calendar.constants';
import { useCheckBreakpoint } from '@marriott/mi-ui-library';

/**
 *
 * @param param0
 * // handle flexible view on the datepicker of search form
 * @returns // html element with stepper and month pill
 */

const DatePickerFlexible: FC<DatePickerFlexibleProps> = ({
  monthList,
  onDateSelection,
  clickTrackingLoc,
  singleDateLimit,
}) => {
  const { maxNumberOfNights, howManyNightsText, howManyNights, isTabbedSearchForm } = useStaticDataContext();
  const dates = useSearchFormStore((state: any) => state.dates);

  /**component connstant */
  const currMonth = Number(moment().format('M')) - 1;
  const currYear = Number(moment().format('YYYY'));

  const nextYear = currYear + 1;
  const nightsLimit = maxNumberOfNights ? Number(maxNumberOfNights) : MAX_NUMBER_OF_NIGHTS_ALLOWED;
  /** component local states */
  const [nightCount, setNightCount] = useState<number>(dates?.numberOfNights || 1); //night count to display
  const [selectedMonth, setSelectedMonth] = useState<number>(Number(moment(dates.fromDate).format('M')) - 1);
  const [selectedYear, setSelectedYear] = useState<number>(Number(moment(dates.fromDate).format('YYYY')));
  const lastMonthToShow = Number(moment(singleDateLimit).format('M')) - 1;
  const lastYeartoShow = Number(moment(singleDateLimit).format('YYYY'));

  useEffect(() => {
    setNightCount(dates?.numberOfNights || 1);
    setSelectedMonth(Number(moment(dates.fromDate).format('M')) - 1);
    setSelectedYear(Number(moment(dates.fromDate).format('YYYY')));
  }, [dates.fromDate, dates.numberOfNights]);

  /**
   *set from and end date on month selection
   * and night count
   * <month index>, nightcount or length stay
   */
  const updateDateOnDateSelection = (month: number, year: number, nightCount = 1) => {
    let startDate = moment().month(month).startOf('month');
    startDate = year === nextYear ? startDate.add(1, 'y') : startDate; // add one year if we have selected next year
    startDate = startDate.isBefore(getCurrentDateObject()) ? getCurrentDateObject() : startDate;

    const endDate = moment(startDate.toDate()).add(nightCount, 'days');
    onDateSelection(startDate, endDate, true); // set date in case of user switch the tabs
  };
  const onMonthSelection = (monthInd: number, year: number): void => {
    /**
     * on month selection update the year and month
     */
    setSelectedYear(year);
    setSelectedMonth(monthInd);
    updateDateOnDateSelection(monthInd, year, nightCount);
  };

  const onNightCountAdd = (): void => {
    /**
     * on update night count
     */
    const nightsCountInFlexibleSearch = nightCount < Number(nightsLimit) ? nightCount + 1 : Number(nightsLimit);
    setNightCount(nightsCountInFlexibleSearch);
    updateDateOnDateSelection(selectedMonth, selectedYear, nightsCountInFlexibleSearch);
  };

  const onNightCountDecr = (): void => {
    const nightsCountInFlexibleSearch = nightCount <= 1 ? 1 : nightCount - 1;
    setNightCount(nightsCountInFlexibleSearch);
    updateDateOnDateSelection(selectedMonth, selectedYear, nightsCountInFlexibleSearch);
  };

  const viewportL = useCheckBreakpoint('viewportL');

  const MonthPill = (index: number, month: string, year: number) => (
    /**
     * reusable button used to render month pill in the flxible section
     */
    <Button
      key={`${index}-${month}-${year}`}
      testId={`${index}-button`}
      ariaExpanded={selectedMonth === index && selectedYear === year}
      className={clsx(
        'custom_click_track',
        'm-button-s',
        'mb-2 mr-2',
        selectedMonth === index && selectedYear === year
          ? 'selected-month m-button-primary-inverse '
          : 'm-button-secondary default-month-state',
        'month-pill'
      )}
      {...{
        custom_click_track_value: `${clickTrackingLoc}| Flexible Date Picker Month Selected ${month} button |internal`,
      }}
      callback={(): void => onMonthSelection(index, year)}
    >
      <span className="d-inline">{month}</span>
    </Button>
  );

  return (
    <StyledFlexiCalendarBoxDiv className="search__flexible_calendar px-lg-1 pb-5 pb-lg-0" data-test-id="flexible">
      <div className={clsx('stepper-wrapper')}>
        <div className="weekend-selector-horizontal-line-flexible mt-2 mb-4 d-lg-none"></div>
        <StepperTextBlock
          copyText={howManyNightsText || howManyNights}
          stepperValue={nightCount}
          handleIncrement={onNightCountAdd}
          handleDecrement={onNightCountDecr}
          disableDecrement={nightCount === 1}
          disableIncrement={nightCount === nightsLimit}
          decreamentLabel="night count decrease"
          increamentLabel="night count increase"
          clickTrackloc={`${clickTrackingLoc}  Flexible| night count`}
        />
      </div>
      <div className={clsx('months-pill-container', 'pb-5 mb-5 mb-lg-0', !isTabbedSearchForm && 'months-pill-spacing')}>
        <div className="t-font-alt-xs display-year">{currYear}</div>
        <div className="d-flex flex-wrap mx-0 py-2">
          {monthList?.map((month, index) => {
            // index >= (lastYeartoShow === currYear ? lastMonthToShow : currMonth)
            return lastYeartoShow === currYear
              ? index <= lastMonthToShow && index >= currMonth && MonthPill(index, month, currYear)
              : index >= currMonth && MonthPill(index, month, currYear);
          })}
        </div>
        {nextYear <= lastYeartoShow && (
          <>
            <div className={clsx('t-font-alt-xs', 'display-year', viewportL && 'pt-2')}>{nextYear}</div>
            <div className={clsx('d-flex', 'flex-wrap', ' mx-0', ' py-2')}>
              {monthList?.map((month, index) => {
                return index <= lastMonthToShow && MonthPill(index, month, nextYear);
              })}
            </div>
          </>
        )}
      </div>
    </StyledFlexiCalendarBoxDiv>
  );
};

export default DatePickerFlexible;
